import {
  Avatar,
  Dialog,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat } from "vnpt-chat";
import AddChatMember from "../../../features/AddChatMember/AddChatMember";
import "../style.scss";

interface Props {
  room?: Chat;
  openBoxChatInfor: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarBoxChat: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const HeaderBoxChatGroup: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [room, setRoom] = useState<Chat | undefined>();
  const [openBoxChatInfor, setOpenBoxChatInfor] = useState(true);
  const [openFormAddChatMember, setOpenFormAddChatMember] = useState(false);

  useEffect(() => {
    setRoom(props.room);
  }, [props.room]);

  vchat.on("chat.memberAdded", (chat) => {
    setRoom(chat);
  });
  vchat.on("chat.memberRemoved", (chat) => {
    setRoom(chat);
  });
  vchat.on("chat.edited", (chat) => {
    setRoom(chat);
  });

  const firstLetter = room?.title?.slice(0, 1) || "u";

  const handleOpenBoxChatInfor = () => {
    setOpenBoxChatInfor((x) => !x);
    props.openBoxChatInfor(openBoxChatInfor);
  };

  const AddChatMemberHandler = () => {
    setOpenFormAddChatMember(true);
  };

  const closeAddMemberHandler = () => {
    setOpenFormAddChatMember(false);
  };

  return (
    <Fragment>
      <div className="header">
        <div className="header_information">
          <div className="avatar" onClick={handleOpenBoxChatInfor}>
            <Avatar
              className={classes.avatarBoxChat}
              style={{
                backgroundColor: `${room?.avatarColor}`,
              }}
            >
              {firstLetter}
            </Avatar>
          </div>
          <div className="name_member">
            <p className="name">{room?.title || "unknown"}</p>
            <p className="member">
              <i className="far fa-user"></i>
              {room?.members.length} thành viên
            </p>
          </div>
        </div>
        <div className="header_feature">
          <i className="fas fa-user-plus" onClick={AddChatMemberHandler}></i>
          <i
            className="far fa-address-card"
            onClick={handleOpenBoxChatInfor}
          ></i>
        </div>
      </div>

      <Dialog
        open={openFormAddChatMember}
        keepMounted
        onClose={closeAddMemberHandler}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <AddChatMember closeAddChatMember={closeAddMemberHandler} chat={room} />
      </Dialog>
    </Fragment>
  );
};

export default HeaderBoxChatGroup;
