import {
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import React, { Fragment, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import VNPTChat, { Chat, Contact, User } from "vnpt-chat";
import ContactFriend from "./Contact/Contact";
import ContactAdded from "./ContactAdded/ContactAdded";
import { removeListContact } from "./listContactSlice";
import "./SendContactMessage.scss";

interface Props {
  closeFormSendContact: (value: boolean) => void;
  chat?: Chat;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const SendContactMessage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const dispatch = useDispatch();
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User>();
  const [list, setList] = useState<Contact[]>();

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat, vchat]);

  const [listContact, setListContact] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  vchat.on("contactsList.updated", (chats) => {
    setListContact(vchat.contacts);
  });

  const ListUserRedux = useSelector(
    (state: RootStateOrAny) => state.listShareContact
  );

  useEffect(() => {
    const result = listContact.filter(
      ({ userId: id1 }) =>
        !ListUserRedux.listShareContact.some(
          (user: any) => id1 === user.user.id
        )
    );
    setList(result);
  }, [ListUserRedux.listShareContact, listContact]);

  const handleCloseFormSendContact = () => {
    props.closeFormSendContact(false);
    ListUserRedux.listShareContact.forEach((value: any) => {
      const action = removeListContact({
        idNeedToRemove: value.user.id,
      });
      dispatch(action);
    });
  };

  const sendContactHandler = () => {
    ListUserRedux.listShareContact.forEach((value: any) => {
      if (user) {
        vchat.shareContactToUser(user.id, value.user.id);
      } else {
        if (chat) {
          vchat.shareContactToChat(chat.id, value.user.id);
        }
      }
      const action = removeListContact({
        idNeedToRemove: value.user.id,
      });
      dispatch(action);
    });
    props.closeFormSendContact(false);
  };

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">{"Gửi danh thiếp"}</DialogTitle>
      <div className="body_shareContact">
        <div className="search_Contact">
          <div className="input_shareContact">
            <TextField
              size="small"
              id="outlined-textarea"
              label="Tên Người Dùng"
              placeholder="Hãy nhập tên hoặc username"
              variant="outlined"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>
        <div className="list_contacts">
          <div className="contacts">
            {list?.map((contact) => {
              return <ContactFriend contact={contact} key={contact.userId} />;
            })}
          </div>
          <div className="contacts_add">
            {ListUserRedux.listShareContact.map((contact: Contact) => {
              return <ContactAdded key={contact.user?.id} contact={contact} />;
            })}
          </div>
        </div>
      </div>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCloseFormSendContact}
        >
          Hủy
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          endIcon={<Icon>send</Icon>}
          onClick={sendContactHandler}
        >
          Gửi
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default SendContactMessage;
