import React, { useEffect, useState } from "react";
import { Message } from "vnpt-chat";

interface Props {
  closeEditMessage: (value: boolean) => void;
  message?: Message;
}
const EditMessage: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<Message>();

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const closeEditMessage = () => {
    props.closeEditMessage(true);
  };

  return (
    <div className="edit-container">
      <div className="edit-name">Chỉnh sửa tin nhắn</div>
      <div className="reply-content">{message?.text}</div>
      <i className="fas fa-times" onClick={closeEditMessage}></i>
    </div>
  );
};

export default EditMessage;
