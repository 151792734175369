import { createSlice } from "@reduxjs/toolkit";

const listChatMemberSlice = createSlice({
  name: "listChatMember",

  initialState: {
    listChatMember: ([] as Array<Object>) || null,
    listUserId: [] as Array<string>,
  },

  reducers: {
    addListChatMember(state, action) {
      const newUser = action.payload;
      const index = state.listChatMember.findIndex(
        (x: any) => x.user.id === newUser.user.id
      );
      if (index < 0) {
        state.listChatMember.push(newUser);
        state.listUserId.push(newUser.user.id);
      }
    },

    removeListChatMember(state, action) {
      const idNeedToRemove = action.payload.idNeedToRemove;
      state.listChatMember = state.listChatMember.filter(
        (x: any) => x.user.id !== idNeedToRemove
      );
      state.listUserId = state.listUserId.filter(
        (x: any) => x !== idNeedToRemove
      );
    },
  },
});

const { actions, reducer } = listChatMemberSlice;
export const { addListChatMember, removeListChatMember } = actions;
export default reducer;
