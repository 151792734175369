import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Message } from "vnpt-chat";

interface Props {
  closeFormDeleteMessage: (value: boolean) => void;
  message: Message;
}

const FormDeleteMessage: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const handleClose = () => {
    props.closeFormDeleteMessage(false);
  };

  const deleteMessageHandler = () => {
    if (message.id) {
      vchat.deleteMessage(message.id);
    }
    props.closeFormDeleteMessage(false);
  };

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">{"Xác nhận!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bạn có muốn chắc chắn xóa tin nhắn này không?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Hủy
        </Button>
        <Button color="primary" onClick={deleteMessageHandler}>
          Xác nhận
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormDeleteMessage;
