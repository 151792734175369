import React, { Fragment, useEffect, useState } from "react";
import { Chat, User } from "vnpt-chat";
import BoxChatBody from "../BoxChatBody";
import "../style.scss";
import HeaderBoxChatUser from "./HeaderBoxChatUser";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PinnedMessage from "../../../features/PinnedMessage/PinnedMessage";
import "../style.css";

interface Props {
  chat?: Chat;
  openBoxChatInfor: (value: boolean) => void;
}

const BoxChatUser: React.FC<Props> = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User | undefined>();
  const [hasPinMessage, setHasPinMessage] = useState(false);

  useEffect(() => {
    setChat(props.chat);
    if (
      props.chat?.pinnedMessageId !== undefined &&
      props.chat?.pinnedMessageId.length > 0
    ) {
      setHasPinMessage(true);
    } else {
      setHasPinMessage(false);
    }
  }, [chat?.pinnedMessageId, props.chat]);

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat]);

  const handleOpenBoxChatInfor = () => {
    props.openBoxChatInfor(true);
  };

  return (
    <Fragment>
      <HeaderBoxChatUser
        user={user}
        openBoxChatInfor={handleOpenBoxChatInfor}
      />

      {chat ? (
        chat.pinnedMessageId === undefined ? (
          ""
        ) : chat.pinnedMessageId.length <= 0 ? (
          ""
        ) : (
          <div
            className={`${"message-pin"} ${
              hasPinMessage ? "message-pin-active" : ""
            }`}
          >
            <div className="message-pin-title">Tin nhắn được ghim</div>
            <div className="message-pin-content">
              <Slider {...settings}>
                {chat.pinnedMessageId.map((idpinMessage) => {
                  return (
                    <PinnedMessage
                      key={idpinMessage}
                      idPinMessage={idpinMessage}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        )
      ) : (
        ""
      )}

      <BoxChatBody chat={chat} />
    </Fragment>
  );
};

export default BoxChatUser;
