import React, { useEffect, useState } from "react";
import VNPTChat, { Contact, FileDownload, Message, User } from "vnpt-chat";

interface Props {
  replyMessage: Message;
  user?: User;
  sender?: Contact;
}

const ReplyMessagePhoto: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [replyMessage, setReplyMessage] = useState<Message>(props.replyMessage);
  const [user, setUser] = useState<User>();
  const [sender, setSender] = useState<Contact>();
  const [messageUrl, setMessageUrl] = useState<string>();
  const [documentDownload, setDocumentDownload] = useState<FileDownload>();

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
    props.replyMessage.loadPhoto();

    if (replyMessage) {
      setMessageUrl(replyMessage.photoUrl);
    }
  }, [props.replyMessage, replyMessage]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
    if (props.sender) {
      setSender(props.sender);
    }
  }, [props.sender, props.user]);

  useEffect(() => {
    vchat.on("fileDownload.new", (fileDownload) => {
      replyMessage.photo.forEach((item) => {
        if (item.fileId === fileDownload.fileId) {
          setDocumentDownload(fileDownload);
        }
      });
    });
  }, [replyMessage.photo, vchat]);

  useEffect(() => {
    if (documentDownload) {
      documentDownload.on("updated", () => {
        setMessageUrl(documentDownload.objectUrl);
        replyMessage.loadPhoto();
      });
    }
  }, [documentDownload, replyMessage]);

  return (
    <div className="message-reply-photo">
      <div className="message-reply-photo-title">Trả lời</div>
      <div className="message-reply-photo-content">
        <div className="message-reply-photo-left">
          <img src={messageUrl} alt="" />
        </div>
        <div className="message-reply-photo-right">
          <div className="message-reply-sender">
            {sender?.displayName || user?.displayName}
          </div>
          <div className="message-reply-content">{replyMessage?.text}</div>
        </div>
      </div>
    </div>
  );
};

export default ReplyMessagePhoto;
