import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import "./UpdateContact.scss";
import { deepPurple } from "@material-ui/core/colors";
import VNPTChat, { Chat, Contact, User } from "vnpt-chat";

interface Props {
  closeEditContact: (value: boolean) => void;
  contact?: Contact;
  user?: User;
  chat?: Chat;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const UpdateContact: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();
  const [contact, setContact] = useState<Contact>();
  const [user, setUser] = useState<User>();
  const [chat, setChat] = useState<Chat>();
  const [enteredDisplayName, setEnteredDisplayName] = useState();

  useEffect(() => {
    setContact(props.contact);
    setUser(props.user);
    setChat(props.chat);
  }, [props.contact, props.user, props.chat]);

  const closeEditHandler = () => {
    props.closeEditContact(false);
  };

  const firstLetter = chat?.isGroup
    ? chat?.title?.slice(0, 1)
    : contact?.displayName?.slice(0, 1) || "u";

  const inputHandler = (event: any) => {
    setEnteredDisplayName(event.target.value);
  };

  const updateContactHandler = () => {
    if (contact) {
      vchat.updateContact(
        contact.userId,
        enteredDisplayName || contact.displayName
      );
    } else {
      if (chat) {
        vchat.updateGroupChat(chat.id, enteredDisplayName || chat.title);
      }
    }
    props.closeEditContact(false);
  };

  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">
        {props.chat?.isGroup ? "Chỉnh sửa hội thoại" : "Chỉnh sửa liên hệ"}
      </DialogTitle>
      <DialogContent>
        <div className="update-contact">
          <div className="update-contact-avatar">
            <Avatar
              className={classes.member}
              style={{
                backgroundColor: `${
                  chat?.isGroup ? chat?.avatarColor : user?.avatarColor
                }`,
              }}
            >
              {firstLetter}
            </Avatar>
          </div>
          <div className="update-contact-edit">
            <TextField
              id="outlined-basic"
              label="Tên hiển thị"
              variant="outlined"
              size="small"
              defaultValue={
                props.chat?.isGroup
                  ? props.chat?.title
                  : props.contact?.displayName
              }
              autoFocus
              fullWidth
              onChange={inputHandler}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={closeEditHandler}>
          Hủy
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={updateContactHandler}
        >
          Cập nhập
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default UpdateContact;
