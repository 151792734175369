import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Chat, Message } from "vnpt-chat";
import "./style.scss";
import moment from "moment";
import Moment from "react-moment";

interface Props {
  room: Chat;
  onSendRoomToListRoomFromChatGroup: (value: Chat) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const ChatGroup: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();

  const [chat, setChat] = useState<Chat>(props.room);
  const [lastMessage, setLastMessage] = useState<Message>();

  useEffect(() => {
    if (props.room) {
      setChat(props.room);
      setLastMessage(chat.lastMessage);
    }
  }, [chat.lastMessage, props.room]);

  const firstLetter = chat?.title?.slice(0, 1) || "u";

  const handleOpenBoxChat = () => {
    props.onSendRoomToListRoomFromChatGroup(chat);
  };

  const time = moment(lastMessage?.createdAt);

  return (
    <div className={`${"room"}`} onClick={handleOpenBoxChat}>
      <div className="room_avatar">
        <Avatar
          className={classes.member}
          style={{ backgroundColor: `${chat.avatarColor}` }}
        >
          {firstLetter}
        </Avatar>
      </div>
      <div className="room_infor">
        <div className="room_name">
          <div className="name">
            <i className="fas fa-user-friends"></i>
            <p>{chat.title || "unknown"}</p>
          </div>
          <p className="time">
            <Moment format="HH:mm">{time}</Moment>
          </p>
        </div>
        <div className="lastMessage">
          {lastMessage?.type === "text" && (
            <div className="lastMessage_text">{lastMessage?.text}</div>
          )}
          {lastMessage?.type === "document" && (
            <div className="lastMessage_file">
              <i className="far fa-file"></i>
              Document
            </div>
          )}
          {lastMessage?.type === "file" && (
            <div className="lastMessage_file">
              <i className="far fa-file"></i>
              File
            </div>
          )}
          {lastMessage?.type === "photo" && (
            <div className="lastMessage_file">
              <i className="far fa-file-image"></i>
              Hình ảnh
            </div>
          )}
          {lastMessage?.type === "contact" && (
            <div className="lastMessage_file">
              <i className="far fa-id-badge"></i>
              Danh thiếp
            </div>
          )}
          {lastMessage?.type === "forward" && (
            <p>
              {lastMessage?.forwardedMessage.type === "photo" && (
                <div className="lastMessage_file">
                  <i className="far fa-file-image"></i>
                  Hình ảnh
                </div>
              )}
              {lastMessage?.forwardedMessage.type === "text" && (
                <div className="lastMessage_text">
                  {lastMessage?.forwardedMessage.text}
                </div>
              )}
              {lastMessage?.forwardedMessage.type === "file" && (
                <div className="lastMessage_file">
                  <i className="far fa-file"></i>
                  File
                </div>
              )}
              {lastMessage?.forwardedMessage.type === "contact" && (
                <div className="lastMessage_file">
                  <i className="far fa-id-badge"></i>
                  Danh thiếp
                </div>
              )}
            </p>
          )}
          {lastMessage?.type === "system" && (
            <div className="lastMessage_text">
              {lastMessage?.text || "Text of system message"}
            </div>
          )}
          {lastMessage?.type === "deleted" && <div>Tin nhắn đã được xóa</div>}
        </div>
      </div>
    </div>
  );
};

export default ChatGroup;
