import React, { useState } from "react";
import { Message } from "vnpt-chat";

interface Props {
  message: Message;
}
const MessageDeleted: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<Message>(props.message);

  return <div className="message_delete">Tin nhắn đã bị xóa</div>;
};

export default MessageDeleted;
