import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Contact } from "vnpt-chat";
import { addListChatMember } from "../listUserSlice";
import "../style.scss";

interface Props {
  contact: Contact;
  member: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Friend: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [contact] = useState(props.contact);

  const [user, setUser] = useState(contact.user);

  if (!user) {
    contact?.loadUser().then(setUser);
  }

  const firstLetter = contact.displayName?.slice(0, 1) || "u";

  const dispatch = useDispatch();

  const addContactToListShareContactHandler = () => {
    const action = addListChatMember({
      user,
    });
    dispatch(action);
  };

  return (
    <Fragment>
      <div className="contact" onClick={addContactToListShareContactHandler}>
        <div className={`${"contact_checkIcon"} `}>
          <CheckIcon fontSize="small" />
        </div>
        <div className="contact_avatar">
          <Avatar
            className={classes.avatar_user}
            style={{ backgroundColor: `${user?.avatarColor}` }}
          >
            {firstLetter}
          </Avatar>
        </div>
        <div className="contact_name">{contact.displayName}</div>
      </div>
    </Fragment>
  );
};

export default Friend;
