"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chat = void 0;
const __1 = __importStar(require(".."));
class Chat {
    constructor(chat) {
        this.vchat = __1.default.instance;
        if (!chat) {
            return;
        }
        this.id = chat.id;
        this.type = chat.type;
        this.title = chat.title;
        this.ownerId = chat.ownerId;
        this.members = chat.members;
        this.pinnedMessageId = chat.pinnedMessageId;
        this.lastMessageId = chat.lastMessageId;
        this.avatarColor = chat.avatarColor;
        this.avatar = chat.avatar;
        this.createdAt = chat.createdAt;
        this.updatedAt = chat.updatedAt;
    }
    get isIndividual() {
        return this.type == __1.ChatType.Individual;
    }
    get isGroup() {
        return this.type == __1.ChatType.Group;
    }
    hasMember(userId) {
        return this.members.some((m) => m.userId == userId);
    }
    get safeTitle() {
        var _a, _b, _c;
        if (this.isGroup) {
            return this.title;
        }
        if (this.isIndividual) {
            const contact = this.userPartner && this.vchat.contact(this.userPartner.id);
            return (_c = (_a = contact === null || contact === void 0 ? void 0 : contact.displayName) !== null && _a !== void 0 ? _a : (_b = this.userPartner) === null || _b === void 0 ? void 0 : _b.displayName) !== null && _c !== void 0 ? _c : "Unknown";
        }
        return "";
    }
    get avatarUrl() {
        const avatar = this.avatar && this.avatar[0];
        const fileDownload = avatar && this.vchat.fileDownload(avatar.fileId);
        return fileDownload === null || fileDownload === void 0 ? void 0 : fileDownload.objectUrl;
    }
    get partner() {
        if (!this.isIndividual) {
            return;
        }
        const currentUser = this.vchat.currentUser;
        return this.members.find((m) => m.userId != (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id));
    }
    get userPartner() {
        return this.partner && this.vchat.user(this.partner.userId);
    }
    get messages() {
        return this.vchat.chatMessages(this.id);
    }
    get messagesLoading() {
        return this.vchat.chatMessagesLoading(this.id);
    }
    get hasReachedMax() {
        return this.vchat.hasReachedMax(this.id);
    }
    get lastMessage() {
        return this.lastMessageId
            ? this.vchat.message(this.lastMessageId)
            : undefined;
    }
    loadPartner() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.partner) {
                return;
            }
            if (!this.userPartner) {
                yield this.vchat.getUser(this.partner.userId);
            }
            return this.userPartner;
        });
    }
    loadAvatar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.avatarUrl) {
                const avatar = this.avatar && this.avatar[0];
                avatar &&
                    (yield this.vchat.downloadGroupChatAvatar(this.id, avatar.fileId));
            }
            return this.avatarUrl;
        });
    }
}
exports.Chat = Chat;
