import React, { useEffect, useState } from "react";
import VNPTChat, { FileDownload, Message } from "vnpt-chat";

interface Props {
  message: Message;
}

const ReplyMessagePhoto: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);
  const [messageUrl, setMessageUrl] = useState<string>();
  const [documentDownload, setDocumentDownload] = useState<FileDownload>();

  useEffect(() => {
    setMessage(props.message);
    props.message.loadPhoto();

    if (message) {
      setMessageUrl(message.photoUrl);
    }
  }, [message, props.message]);

  useEffect(() => {
    vchat.on("fileDownload.new", (fileDownload) => {
      message.photo.forEach((item) => {
        if (item.fileId === fileDownload.fileId) {
          setDocumentDownload(fileDownload);
        }
      });
    });
  }, [message.photo, vchat]);

  useEffect(() => {
    if (documentDownload) {
      documentDownload.on("updated", () => {
        setMessageUrl(documentDownload.objectUrl);
        message.loadPhoto();
      });
    }
  }, [documentDownload, message]);
  return (
    <div className="reply-message-image">
      <div className="reply-message-img">
        <img src={messageUrl} alt="" />
      </div>
      <div className="reply-message-img-title">[Hình ảnh]</div>
    </div>
  );
};

export default ReplyMessagePhoto;
