import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import VNPTChat from "vnpt-chat";

interface Props {
  closeFormLogOut: (value: boolean) => void;
}

const LogOut: React.FC<Props> = (props) => {
  const History = useHistory();

  const handleCloseFormLogOut = () => {
    props.closeFormLogOut(false);
  };

  const handleLogOut = () => {
    const vchat = VNPTChat.instance;
    vchat.logout();
    localStorage.removeItem("signin");
    History.push("/signin");
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Xác nhận</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bạn có muốn đăng xuất ra khỏi VNPT CHAT?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseFormLogOut} color="primary">
          Hủy
        </Button>
        <Button onClick={handleLogOut} color="primary">
          Xác nhận
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default LogOut;
