import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Contact, User } from "vnpt-chat";

interface Props {
  closeDeleteContact: (value: boolean) => void;
  user?: User;
}

const DeleteContact: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [user, setUser] = useState<User | undefined>(props.user);
  const [contact, setContact] = useState<Contact | undefined>();

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  const handleClose = () => {
    props.closeDeleteContact(false);
  };

  const deleteContactHandler = () => {
    if (user) {
      vchat.deleteContact(user.id);
    }
    props.closeDeleteContact(false);
  };

  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">{"Xác nhận"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Xóa <b> {contact?.displayName} </b> khỏi danh sách liên hệ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Hủy
        </Button>
        <Button onClick={deleteContactHandler} color="primary" autoFocus>
          Xác nhận
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default DeleteContact;
