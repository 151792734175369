import React, { useEffect, useState } from "react";
import VNPTChat, { Chat } from "vnpt-chat";
import ChatGroup from "./ChatGroup/ChatGroup";
import "./ListChatGroup.scss";

interface Props {
  openChatGroup: (value: Chat) => void;
  isOpenListGroupChatByClick: boolean;
}

const ListChatGroup: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [isActive, setIsActive] = useState(false);
  const [listChatGroup, setListChatGroup] = useState<Array<Chat>>([]);
  const [listChat, setListChat] = useState(() => {
    const list = vchat.chats;
    return list;
  });

  vchat.on("chatsList.updated", (chats) => {
    setListChat(vchat.chats);
  });

  useEffect(() => {
    listChat.forEach((chat) => {
      if (chat.isGroup) {
        setListChatGroup((prev) => [...prev, chat]);
      }
    });
  }, [listChat]);

  const openChatGroup = (value: Chat) => {
    props.openChatGroup(value);
  };

  useEffect(() => {
    if (props.isOpenListGroupChatByClick) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props.isOpenListGroupChatByClick]);

  return (
    <div className={`${"listChatGroup"} ${isActive ? "isActive" : ""}`}>
      <div className="listChatGroup-header">
        <div className="listChatGroup-icon">
          <i className="fas fa-users"></i>
        </div>
        <div className="listChatGroup-title">Danh sách nhóm</div>
      </div>
      <div className="listChatGroup-list">
        {listChatGroup.map((chat) => {
          return (
            <ChatGroup
              chat={chat}
              key={chat.id}
              openChatGroup={openChatGroup}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListChatGroup;
