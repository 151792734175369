import { Avatar, Button, Dialog, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat, Contact, Message, User } from "vnpt-chat";
import DeleteContact from "../../../features/DeleteContact/DeleteContact";
import LeaveChat from "../../../features/LeaveChat/LeaveChat";
import UpdateContact from "../../../features/UpdateContact/UpdateContact";
import "../style.scss";
import BoxChatFile from "./BoxChatFile/BoxChatFile";
import BoxChatImage from "./BoxChatImage/BoxChatImage";
import Member from "./Member/Member";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddContact from "../../../features/AddContact/AddContact";

interface Props {
  chat?: Chat;
  closeBoxChatDetail: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarBoxChatInfor: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
}));

const BoxChatDetail: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User | undefined>();
  const [contact, setContact] = useState<Contact>();
  const [messages, setMessages] = useState<Message[]>();
  const [openLeaveChat, setOpenLeaveChat] = useState(false);
  const [openDeleteContact, setOpenDeleteContact] = useState(false);
  const [openEditContact, setOpenEditContact] = useState(false);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [listFriend, setListFriend] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  vchat.on("contactsList.updated", (chats) => {
    setListFriend(vchat.contacts);
  });

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  vchat.on("chat.memberAdded", (chat) => {
    setChat(chat);
  });
  vchat.on("chat.edited", (chat) => {
    setChat(chat);
  });
  vchat.on("chat.edited", (chat) => {
    setChat(chat);
  });

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  vchat.on("contact.edited", (contact) => {
    setContact(contact);
  });

  const firstLetter = chat?.isGroup
    ? chat?.title?.slice(0, 1)
    : contact?.displayName?.slice(0, 1) ||
      user?.displayName?.slice(0, 1) ||
      "u";

  useEffect(() => {
    if (chat) {
      setMessages(vchat.chatMessages(chat?.id));
    }
  }, [chat, vchat]);

  vchat.on("message.received", (message) => {
    if (chat) {
      setMessages(vchat.chatMessages(chat?.id));
    }
  });

  const leaveChatHandler = () => {
    setOpenLeaveChat(true);
  };
  const CloseLeaveChatHandler = () => {
    setOpenLeaveChat(false);
  };

  const deleteContactHandler = () => {
    setOpenDeleteContact(true);
  };

  const closeDeleteContact = () => {
    setOpenDeleteContact(false);
  };

  const editChatHandler = () => {
    setOpenEditContact(true);
  };

  const closeEditContact = () => {
    setOpenEditContact(false);
  };

  const closeBoxChatDetail = () => {
    props.closeBoxChatDetail(true);
  };

  const addContactHandler = () => {
    setOpenAddContact(true);
  };
  const closeAddContact = () => {
    setOpenAddContact(false);
  };

  return (
    <Fragment>
      <div className="boxchat_information_title">
        {chat?.isGroup ? "Thông tin nhóm" : "Thông tin hội thoại"}
        <i className="fas fa-times" onClick={closeBoxChatDetail}></i>
      </div>

      <div className="boxchat_information_name_avatar">
        <div className={classes.root}>
          <Avatar
            className={classes.avatarBoxChatInfor}
            style={{
              backgroundColor: `${
                chat?.isGroup ? chat?.avatarColor : user?.avatarColor
              }`,
            }}
          >
            {firstLetter}
          </Avatar>
        </div>
        <div className="boxchat_information_name">
          {chat?.isGroup
            ? chat?.title
            : contact?.displayName || user?.displayName || "unknown"}
        </div>
      </div>

      {chat?.isGroup ? (
        <div className="boxchat_information_members">
          <div className="boxchat_information_members_title">
            <i className="fas fa-user-friends"></i>
            <p> {chat?.members.length} THÀNH VIÊN</p>
          </div>
          <div className="boxchat_information_members_list">
            {chat?.members.map((data) => {
              return (
                <Member member={data} key={data.userId} chat={chat}></Member>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="boxchat_information_image">
        <div className="boxchat_information_image_top">
          <i className="far fa-file-image"></i>
          <p>Ảnh</p>
        </div>
        <div className="boxchat_information_image_bottom">
          {messages?.map((data) => {
            return (
              data.isPhotoMessage && (
                <BoxChatImage key={data.id} message={data} />
              )
            );
          })}
        </div>
      </div>

      <div className="boxchat_information_file">
        <div className="boxchat_information_file_top">
          <i className="far fa-file"></i>
          <p>File</p>
        </div>
        <div className="boxchat_information_file_bottom">
          {messages?.map((data) => {
            return (
              data.isDocumentMessage && (
                <BoxChatFile key={data.id} message={data} />
              )
            );
          })}
        </div>
      </div>

      {chat?.isGroup && (
        <Fragment>
          <div className="boxchat_information_edit" onClick={editChatHandler}>
            <Button fullWidth color="secondary" startIcon={<EditIcon />}>
              Chỉnh sửa
            </Button>
          </div>
          <div className="boxchat_information_out">
            <Button
              fullWidth
              color="secondary"
              startIcon={<ExitToAppIcon />}
              onClick={leaveChatHandler}
            >
              Rời nhóm
            </Button>
          </div>
        </Fragment>
      )}
      {chat?.isIndividual && (
        <Fragment>
          {listFriend.findIndex((x) => x.userId === user?.id) === -1 ? (
            <div className="boxchat_information_out">
              <Button
                fullWidth
                color="secondary"
                startIcon={<PersonAddIcon />}
                onClick={addContactHandler}
              >
                Thêm liên hệ
              </Button>
            </div>
          ) : (
            <Fragment>
              <div
                className="boxchat_information_edit"
                onClick={editChatHandler}
              >
                <Button fullWidth color="secondary" startIcon={<EditIcon />}>
                  Chỉnh sửa
                </Button>
              </div>
              <div className="boxchat_information_out">
                <Button
                  fullWidth
                  color="secondary"
                  startIcon={<HighlightOffIcon />}
                  onClick={deleteContactHandler}
                >
                  Xóa liên hệ
                </Button>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      <Dialog
        open={openLeaveChat}
        onClose={CloseLeaveChatHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LeaveChat closeLeaveChat={CloseLeaveChatHandler} chat={chat} />
      </Dialog>

      <Dialog
        open={openDeleteContact}
        onClose={closeDeleteContact}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DeleteContact user={user} closeDeleteContact={closeDeleteContact} />
      </Dialog>

      <Dialog
        open={openEditContact}
        onClose={closeEditContact}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <UpdateContact
          closeEditContact={closeEditContact}
          contact={contact}
          user={user}
          chat={chat}
        />
      </Dialog>

      <Dialog
        open={openAddContact}
        onClose={closeAddContact}
        aria-labelledby="form-dialog-title"
      >
        <AddContact closeFormAddContact={closeAddContact} user={user} />
      </Dialog>
    </Fragment>
  );
};

export default BoxChatDetail;
