import { Avatar, Dialog, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { Fragment, useState } from "react";
import VNPTChat, { Contact, User } from "vnpt-chat";
import DeleteContact from "../../features/DeleteContact/DeleteContact";
import "./style.scss";

interface Props {
  contact: Contact;
  sendContactUserFromContactToPhoneBook: (
    value1: Contact,
    value2: User | undefined
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const Friend: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();
  const [contact, setContact] = useState(props.contact);

  const [user, setUser] = useState(contact.user);
  const [open, setOpen] = useState(false);

  if (!user) {
    contact?.loadUser().then(setUser);
  }

  vchat.on("contact.edited", (con) => {
    if (con.userId === contact.userId) {
      setContact(con);
    }
  });

  const firstLetter = contact?.displayName?.slice(0, 1) || "u";

  const openBoxChat = () => {
    props.sendContactUserFromContactToPhoneBook(contact, user);
  };

  const deleteContactHandler = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className="friend">
        <div className="friend-container" onClick={openBoxChat}>
          <div className="friend_avatar">
            <Avatar
              className={classes.member}
              style={{ backgroundColor: `${user?.avatarColor}` }}
            >
              {firstLetter}
            </Avatar>
          </div>
          <div className="friend_infor">
            <p className="friend_name">{contact.displayName}</p>
            <p
              className={`${"friend_status"} ${
                user?.onlineState === "offline" ? "friend_status_offline" : ""
              }`}
            >
              {user?.onlineState ?? "offline"}
            </p>
          </div>
        </div>
        <div className="friend_btn_delete" onClick={deleteContactHandler}>
          <i className="fas fa-backspace"></i>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DeleteContact user={user} closeDeleteContact={handleClose} />
      </Dialog>
    </Fragment>
  );
};

export default Friend;
