import { createSlice } from "@reduxjs/toolkit";

const listContactSlice = createSlice({
  name: "listShareContact",

  initialState: {
    listShareContact: ([] as Array<Object>) || null,
  },

  reducers: {
    addListContact(state, action) {
      const newUser = action.payload;
      const index = state.listShareContact.findIndex(
        (x: any) => x.user.id === newUser.user.id
      );
      if (index < 0) {
        state.listShareContact.push(newUser);
      }
    },

    removeListContact(state, action) {
      const idNeedToRemove = action.payload.idNeedToRemove;
      state.listShareContact = state.listShareContact.filter(
        (x: any) => x.user.id !== idNeedToRemove
      );
    },
  },
});

const { actions, reducer } = listContactSlice;
export const { addListContact, removeListContact } = actions;
export default reducer;
