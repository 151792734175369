import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import React, { useEffect, useState } from "react";
import VNPTChat, { Chat, Message, User } from "vnpt-chat";
import "./SendPhotoMessage.scss";

interface Props {
  chat?: Chat;
  replyMessage?: Message;
  closeReplyMessage: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const SendPhotoMessage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User>();
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<File>();
  const [preview, setPreview] = useState<string>();
  const [caption, setCaption] = useState<string>();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat, vchat]);

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
  }, [props.replyMessage]);

  const onChangeCaption = (e: any) => {
    setCaption(e.target.value);
  };

  const changeHandle = (e: any) => {
    e.preventDefault();
    const fileSelected = e.target.files[0];

    if (fileSelected && fileSelected.type.substr(0, 5) === "image") {
      setImage(fileSelected);
    } else {
      setImage(undefined);
    }

    setOpen(true);

    props.closeReplyMessage(true);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(undefined);
    }
  }, [image]);

  const sendPhotoMessage = () => {
    if (!image) return;

    if (user) {
      if (replyMessage) {
        vchat.sendPhotoToUser(user.id, image, {
          replyTo: replyMessage.id,
          text: caption,
        });
      } else {
        vchat.sendPhotoToUser(user.id, image, { text: caption });
      }
    } else {
      if (chat) {
        if (replyMessage) {
          vchat.sendPhotoToUser(chat.id, image, {
            replyTo: replyMessage.id,
            text: caption,
          });
        } else {
          vchat.sendPhotoToChat(chat.id, image, { text: caption });
        }
      }
    }
    setCaption("");
    setOpen(false);
  };

  return (
    <>
      <input type="file" accept="image/*" multiple onChange={changeHandle} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="content-preview">
            <div className="content-preview-image">
              <img src={preview} alt="" />
            </div>
            <div className="content-preview-text">
              <TextField
                id="standard-basic"
                label="Caption"
                size="small"
                autoFocus
                value={caption}
                fullWidth
                onChange={onChangeCaption}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="outlined">
            Hủy
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<Icon>send</Icon>}
            onClick={sendPhotoMessage}
          >
            Gửi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendPhotoMessage;
