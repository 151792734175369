import { Avatar, LinearProgress, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Contact, Message, User } from "vnpt-chat";

interface Props {
  message: Message;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const DraftMessageContact: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [message] = useState<Message>(props.message);

  const [user, setUser] = useState<User>();
  const [contact, setContact] = useState<Contact>();

  useEffect(() => {
    if (message.sharedUserId) {
      vchat.getUser(message.sharedUserId).then(setUser);
    }
  }, [message.sharedUserId, vchat]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user?.id));
    }
  }, [user, vchat]);

  const firstLetter =
    contact?.displayName.slice(0, 1) || user?.displayName?.slice(0, 1) || "u";

  return (
    <>
      <div className="message_contact">
        <div className="contact_top">
          <div className="contact_avatar">
            <Avatar
              className={classes.member}
              style={{ backgroundColor: `${user?.avatarColor}` }}
            >
              {firstLetter}
            </Avatar>
          </div>
          <div className="contact_infor">
            <p className="contact_title">Danh thiếp</p>
            <p className="contact_name">
              {contact?.displayName || user?.displayName || "unknown"}
            </p>
          </div>
        </div>
        <div className="contact_bottom">
          <p className="chat">NHẮN TIN</p>
        </div>
      </div>
      <div className="message_time">
        <LinearProgress />
        <p>Đang gửi</p>
      </div>
    </>
  );
};

export default DraftMessageContact;
