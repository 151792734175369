import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat, Message, User } from "vnpt-chat";

interface Props {
  chat?: Chat;
  replyMessage?: Message;
  closeReplyMessage: (value: boolean) => void;
  editMessage?: Message;
  closeEditMessage: (value: boolean) => void;
}

const SendTextMessage: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [enteredChat, setEnteredChat] = useState("");
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User>();
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [editMessage, setEditMessage] = useState<Message>();

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat, vchat]);

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
    if (props.editMessage && props.editMessage.text) {
      setEditMessage(props.editMessage);
      setEnteredChat(props.editMessage.text);
    }
  }, [props.replyMessage, props.editMessage]);

  const chatHandler = (event: any) => {
    setEnteredChat(event.target.value);
    if (enteredChat === "") return;
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if (user) {
        if (replyMessage) {
          vchat.sendTextToUser(user.id, enteredChat, {
            replyTo: replyMessage.id,
          });
        } else if (editMessage && editMessage.id) {
          vchat.editTextMessage(editMessage.id, enteredChat);
        } else {
          vchat.sendTextToUser(user.id, enteredChat);
        }
      } else {
        if (chat) {
          if (replyMessage) {
            vchat.sendTextToChat(chat.id, enteredChat, {
              replyTo: replyMessage.id,
            });
          } else if (editMessage && editMessage.id) {
            vchat.editTextMessage(editMessage.id, enteredChat);
          } else {
            vchat.sendTextToChat(chat.id, enteredChat);
          }
        }
      }

      setEnteredChat("");
      props.closeReplyMessage(true);
      props.closeEditMessage(true);
    }
  };

  const sendTextHandler = () => {
    if (enteredChat === "") return;
    if (user) {
      if (replyMessage) {
        vchat.sendTextToUser(user.id, enteredChat, {
          replyTo: replyMessage.id,
        });
      } else if (editMessage && editMessage.id) {
        vchat.editTextMessage(editMessage.id, enteredChat);
      } else {
        vchat.sendTextToUser(user.id, enteredChat);
      }
    } else {
      if (chat) {
        if (replyMessage) {
          vchat.sendTextToChat(chat.id, enteredChat, {
            replyTo: replyMessage.id,
          });
        } else if (editMessage && editMessage.id) {
          vchat.editTextMessage(editMessage.id, enteredChat);
        } else {
          vchat.sendTextToChat(chat.id, enteredChat);
        }
      }
    }

    setEnteredChat("");
    props.closeReplyMessage(true);
    props.closeEditMessage(true);
  };

  return (
    <Fragment>
      <input
        type="text"
        placeholder="Nhập tin nhắn của bạn"
        onChange={chatHandler}
        onKeyPress={chatHandler}
        value={enteredChat}
      />
      <i className="far fa-laugh-beam"></i>
      <i className="far fa-paper-plane" onClick={sendTextHandler}></i>
    </Fragment>
  );
};

export default SendTextMessage;
