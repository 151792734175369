import React, { Fragment, useEffect, useState } from "react";
import { FileIcon } from "react-file-icon";
import VNPTChat, { FileDownload, Message } from "vnpt-chat";
const prettyBytes = require("pretty-bytes");

interface Props {
  message: Message;
}

const MessageDocument: React.FC<Props> = (props, defaultStyles) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);
  const [sizeDocument, setSizeDocument] = useState();
  const [documentDownload, setDocumentDownload] = useState<FileDownload>();
  const [downloadedDocument, setDownloadedDocument] = useState<number>();
  const [downloadStatus, setDownloadStatus] = useState<string>();

  useEffect(() => {
    setMessage(props.message);
    setSizeDocument(prettyBytes(props.message.document.fileSize ?? 0));

    const document = props.message.documentDownload;
    if (document) {
      setDocumentDownload(document);
      setDownloadStatus(document.status);
    }
  }, [props.message]);

  useEffect(() => {
    vchat.on("fileDownload.new", (fileDownload) => {
      if (message.documentDownload?.fileId === fileDownload.fileId) {
        setDocumentDownload(message.documentDownload);
      }
    });
  }, [vchat, message.documentDownload, documentDownload]);

  useEffect(() => {
    if (documentDownload) {
      documentDownload.on("updated", () => {
        setDownloadedDocument(documentDownload.downloaded);
        setDownloadStatus(documentDownload.status);
      });
    }
  }, [documentDownload, downloadStatus, downloadedDocument]);

  const handleDownloadDocument = () => {
    message.loadDocument();
  };

  const uploadFile = props.message.document.fileName.split(".");
  const filesTypes = uploadFile[uploadFile.length - 1];

  return (
    <>
      <div className="message_file">
        <div className="message_file_container">
          <div className="message_file_container_top">
            <div className="icon_file">
              <FileIcon extension={filesTypes} {...defaultStyles[filesTypes]} />
            </div>
            <div className="message_file_right">
              <div className="name_file">{message?.document.fileName}</div>
              <div className="size_file">
                {!documentDownload ? (
                  <Fragment>
                    {prettyBytes(downloadedDocument ?? 0)} / {sizeDocument}
                    <i
                      className="fas fa-download"
                      onClick={handleDownloadDocument}
                    ></i>
                  </Fragment>
                ) : downloadStatus === "none" ? (
                  <Fragment>
                    {prettyBytes(downloadedDocument ?? 0)} / {sizeDocument}
                    <i
                      className="fas fa-download"
                      onClick={handleDownloadDocument}
                    ></i>
                  </Fragment>
                ) : downloadStatus === "started" ? (
                  <Fragment>
                    {prettyBytes(downloadedDocument ?? 0)} / {sizeDocument}
                    <i
                      className="fas fa-download"
                      onClick={handleDownloadDocument}
                    ></i>
                  </Fragment>
                ) : downloadStatus === "progress" ? (
                  <Fragment>
                    {prettyBytes(downloadedDocument ?? 0)} / {sizeDocument}
                  </Fragment>
                ) : downloadStatus === "success" ? (
                  <Fragment>
                    <div className="status_success">
                      <i className="far fa-folder-open"> Mở</i>
                    </div>
                  </Fragment>
                ) : downloadStatus === "failed" ? (
                  <Fragment>
                    <div className="status_failed">
                      <i className="fas fa-exclamation-triangle"> Thất bại</i>
                    </div>
                  </Fragment>
                ) : downloadStatus === "cancelled" ? (
                  <Fragment>
                    <div className="status_failed">
                      <i className="fas fa-exclamation-triangle"> Đã hủy</i>
                    </div>
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="message_file_container_bottom">
            <p className="message_file_text">
              {message.text ? message.text : ""}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageDocument;
