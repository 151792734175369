import React, { useState } from "react";
import PropTypes from "prop-types";
import { Message } from "vnpt-chat";

interface Props {
  message: Message;
}
const MessageSystem: React.FC<Props> = (props) => {
  const [messages, setMessages] = useState<Message>(props.message);

  return <div className="message_system">{messages?.text}</div>;
};

export default MessageSystem;
