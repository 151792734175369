import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import React, { useEffect, useState } from "react";
import { FileIcon } from "react-file-icon";
import VNPTChat, { Chat, Message, User } from "vnpt-chat";
import "./SendDocumentMessage.scss";
const prettyBytes = require("pretty-bytes");

interface Props {
  chat?: Chat;
  replyMessage?: Message;
  closeReplyMessage: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const SendDocumentMessage: React.FC<Props> = (props, defaultStyles) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [chat, setChat] = useState<Chat | undefined>();
  const [user, setUser] = useState<User>();
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [open, setOpen] = useState(false);
  const [caption, setCaption] = useState<string>();
  const [file, setFile] = useState<File>();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  useEffect(() => {
    if (!chat?.isGroup) {
      chat?.loadPartner().then(setUser);
    }
  }, [chat, vchat]);

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
  }, [props.replyMessage]);

  const onChangeCaption = (e: any) => {
    setCaption(e.target.value);
  };

  const handleOpen = (e: any) => {
    e.preventDefault();
    const fileSelected = e.target.files[0];

    if (fileSelected) {
      setFile(fileSelected);
    } else {
      setFile(undefined);
    }

    setOpen(true);
    props.closeReplyMessage(true);
  };

  const uploadFile = file?.name.split(".");
  const filesTypes = uploadFile ? uploadFile[uploadFile.length - 1] : "";

  const sendDocumentMessage = () => {
    if (!file) return;

    if (user) {
      if (replyMessage) {
        vchat.sendDocumentToUser(user.id, file, {
          replyTo: replyMessage.id,
          text: caption,
        });
      } else {
        vchat.sendDocumentToUser(user.id, file, { text: caption });
      }
    } else {
      if (chat) {
        if (replyMessage) {
          vchat.sendDocumentToChat(chat.id, file, {
            replyTo: replyMessage.id,
            text: caption,
          });
        } else {
          vchat.sendDocumentToChat(chat.id, file, { text: caption });
        }
      }
    }
    setCaption("");
    setOpen(false);
  };

  return (
    <>
      <input
        type="file"
        accept="file_extension|audio/*|video/*"
        multiple
        onChange={handleOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="content-preview-files">
            <div className="content-preview-file">
              <div className="content-preview-file-icon">
                <FileIcon
                  extension={filesTypes}
                  {...defaultStyles[filesTypes]}
                />
              </div>
              <div className="content-preview-file-right">
                <div className="content-preview-file-right-name">
                  {file?.name}
                </div>
                <div className="content-preview-file-right-size">
                  {prettyBytes(file?.size ?? 0)}
                </div>
              </div>
            </div>
            <div className="content-preview-text">
              <TextField
                id="standard-basic"
                label="Caption"
                size="small"
                autoFocus
                value={caption}
                fullWidth
                onChange={onChangeCaption}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="outlined">
            Hủy
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<Icon>send</Icon>}
            onClick={sendDocumentMessage}
          >
            Gửi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendDocumentMessage;
