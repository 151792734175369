import React, { useEffect, useState } from "react";
import VNPTChat, { Contact, Message, User } from "vnpt-chat";
import ReplyMessagePhoto from "./ReplyMessagePhoto/ReplyMessagePhoto";

interface Props {
  closeReplyMessage: (value: boolean) => void;
  message?: Message;
}

const ReplyMessage: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>();
  const [user, setUser] = useState<User>();
  const [sender, setSender] = useState<Contact>();

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  useEffect(() => {
    if (message?.senderId) {
      vchat.getUser(message.senderId).then(setUser);
    }
  }, [message?.senderId, vchat]);

  const closeReplyMessage = () => {
    props.closeReplyMessage(true);
  };

  useEffect(() => {
    if (user) {
      setSender(vchat.contact(user.id));
    }
  }, [user, vchat]);

  return (
    <div className="reply-container">
      <div className="reply-name">
        Trả lời <b>{sender?.displayName || user?.displayName || "unknow"}</b>
      </div>
      <div className="reply-content">
        {message?.isTextMessage && message.text}
        {message?.isPhotoMessage && <ReplyMessagePhoto message={message} />}
        {message?.isDocumentMessage && `[File] ${message.document.fileName}`}
        {message?.isContactMessage && "Danh thiếp"}

        {message?.isForwardMessage &&
          message.forwardedMessage.isTextMessage &&
          message.forwardedMessage.text}
        {message?.isForwardMessage &&
          message.forwardedMessage.isPhotoMessage &&
          "Hỉnh ảnh"}
        {message?.isForwardMessage &&
          message.forwardedMessage.isDocumentMessage &&
          "Tài liệu"}
        {message?.isForwardMessage &&
          message.forwardedMessage.isContactMessage &&
          "Danh thiếp"}
      </div>
      <i className="fas fa-times" onClick={closeReplyMessage}></i>
    </div>
  );
};

export default ReplyMessage;
