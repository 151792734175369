import {
  Button,
  DialogActions,
  DialogTitle,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat, Message } from "vnpt-chat";
import ChatForwardMessage from "./Chat/ChatForwardMessage";
import "./FormForwardMessage.scss";

interface Props {
  closeFormForwardMessage: (value: boolean) => void;
  message: Message;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const FormForwardMessage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);
  const [chatFormForward, setChatFormForward] = useState<Chat>();
  const [idFormForward, setIdFormForward] = useState<string>();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  const [listChat, setListChat] = useState(() => {
    const list = vchat.chats;
    return list;
  });

  vchat.on("chatsList.updated", (chats) => {
    setListChat(vchat.chats);
  });

  const closeFormForwardMessage = () => {
    props.closeFormForwardMessage(false);
  };

  const receiChatFromChat = (value1: Chat, value2: string, value3: boolean) => {
    setChatFormForward(value1);
    setIdFormForward(value2);
    setSelected(value3);
  };

  const forwardMessageHandler = () => {
    if (selected && idFormForward && message.id) {
      if (chatFormForward?.isIndividual) {
        vchat.forwardMessageToUser(idFormForward, message.id);
      } else {
        vchat.forwardMessageToChat(idFormForward, message.id);
      }
    }

    props.closeFormForwardMessage(false);
  };

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">{"Chia sẻ"}</DialogTitle>
      <div className="body_formForwardMessage">
        <div className="search_Contact">
          <div className="input_shareContact">
            <TextField
              size="small"
              id="outlined-textarea"
              label="Tên cuộc trò chuyện"
              placeholder="Hãy nhập tên cuộc trò chuyện gần đây"
              variant="outlined"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>
        <div className="list_chats">
          <div className="list_chats_title">Tất cả cuộc trò chuyện</div>
          {listChat.map((chat) => {
            return (
              <ChatForwardMessage
                chat={chat}
                key={chat.id}
                sendChatToForwardMessage={receiChatFromChat}
              />
            );
          })}
        </div>
      </div>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          onClick={closeFormForwardMessage}
        >
          Hủy
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          endIcon={<Icon>send</Icon>}
          onClick={forwardMessageHandler}
        >
          Chia sẻ
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormForwardMessage;
