import React, { useEffect, useState } from "react";
import { Contact, Message, User } from "vnpt-chat";

interface Props {
  replyMessage: Message;
  user?: User;
  sender?: Contact;
}

const ReplyMessageText: React.FC<Props> = (props) => {
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [user, setUser] = useState<User>();
  const [sender, setSender] = useState<Contact>();

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
  }, [props.replyMessage]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
    if (props.sender) {
      setSender(props.sender);
    }
  }, [props.sender, props.user]);

  return (
    <div className="message-reply-text">
      <p style={{ color: "red" }}>Trả lời</p>
      <div className="message-reply-sender">
        {sender?.displayName || user?.displayName}
      </div>
      <div className="message-reply-content">{replyMessage?.text}</div>
    </div>
  );
};

export default ReplyMessageText;
