import React, { useEffect, useState } from "react";
import { FileIcon } from "react-file-icon";
import VNPTChat, { Chat, Contact, Message, User } from "vnpt-chat";

interface Props {
  replyMessage: Message;
  user?: User;
  sender?: Contact;
}

const ReplyMessageDocument: React.FC<Props> = (props, defaultStyles) => {
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [user, setUser] = useState<User>();
  const [sender, setSender] = useState<Contact>();

  useEffect(() => {
    if (props.replyMessage) {
      setReplyMessage(props.replyMessage);
    }
  }, [props.replyMessage]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
    if (props.sender) {
      setSender(props.sender);
    }
  }, [props.sender, props.user]);

  const uploadFile = replyMessage?.document.fileName.split(".");
  const filesTypes = uploadFile ? uploadFile[uploadFile.length - 1] : "";

  return (
    <>
      <div className="message-reply-file">
        <div className="message-reply-file-title">Trả lời</div>
        <div className="message-reply-file-content">
          <div className="message-reply-file-left">
            <FileIcon extension={filesTypes} {...defaultStyles[filesTypes]} />
          </div>
          <div className="message-reply-file-right">
            <div className="message-reply-sender">
              {sender?.displayName || user?.displayName}
            </div>
            <div className="message-reply-content">
              {replyMessage?.document.fileName}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyMessageDocument;
