import {
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Fragment, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import VNPTChat, { Chat, Contact } from "vnpt-chat";
import Friend from "./Friend/Friend";
import FriendAdded from "./FriendAdded/FriendAdded";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { removeListChatMember } from "./listUserSlice";

interface Props {
  closeAddChatMember: (value: boolean) => void;
  chat?: Chat;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const AddChatMember: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [chat, setChat] = useState<Chat | undefined>(props.chat);
  const [list, setList] = useState<Contact[]>();
  const dispatch = useDispatch();

  const [listContact, setListContact] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  vchat.on("contactsList.updated", (chats) => {
    setListContact(vchat.contacts);
  });

  const ListUserRedux = useSelector(
    (state: RootStateOrAny) => state.listChatMember
  );

  const handleClose = () => {
    props.closeAddChatMember(false);
    ListUserRedux.listChatMember.map((user: any) => {
      const action = removeListChatMember({
        idNeedToRemove: user.user.id,
      });
      dispatch(action);
    });
  };

  useEffect(() => {
    const result = listContact.filter(
      ({ userId: id1 }) => !chat?.members.some(({ userId: id2 }) => id1 === id2)
    );
    const result2 = result.filter(
      ({ userId: id1 }) =>
        !ListUserRedux.listChatMember.some((user: any) => id1 === user.user.id)
    );
    setList(result2);
  }, [ListUserRedux.listChatMember, chat?.members, listContact]);

  const addMemberToChat = () => {
    if (chat) {
      vchat.addChatMembers(chat.id, ListUserRedux.listUserId);
    }
    props.closeAddChatMember(false);
    ListUserRedux.listChatMember.map((user: any) => {
      const action = removeListChatMember({
        idNeedToRemove: user.user.id,
      });
      dispatch(action);
    });
  };

  return (
    <Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {"Thêm thành viên"}
      </DialogTitle>
      <div className="body_shareContact">
        <div className="search_Contact">
          <div className="input_shareContact">
            <TextField
              size="small"
              id="outlined-textarea"
              label="Tên Người Dùng"
              placeholder="Hãy nhập tên hoặc username"
              variant="outlined"
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>
        <div className="list_contacts">
          <div className="contacts">
            {list?.map((contact) => {
              return (
                <Friend
                  contact={contact}
                  key={contact.userId}
                  member={chat?.members}
                />
              );
            })}
          </div>
          <div className="contacts_add">
            {ListUserRedux.listChatMember.map((contact: Contact) => {
              return <FriendAdded key={contact.user?.id} contact={contact} />;
            })}
          </div>
        </div>
      </div>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
          Hủy
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={addMemberToChat}
          startIcon={<PersonAddIcon />}
        >
          Thêm
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AddChatMember;
