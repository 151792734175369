"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUpload = void 0;
class FileUpload {
    constructor(uploadId, uploaded = 0) {
        this.uploadId = uploadId;
        this.uploaded = uploaded;
    }
}
exports.FileUpload = FileUpload;
