import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat, Message } from "vnpt-chat";
import EditMessage from "../../features/EditMessage/EditMessage";
import ReplyMessage from "../../features/ReplyMessage/ReplyMessage";
import SendContactMessage from "../../features/SendMessage/SendContactMessage/SendContactMessage";
import SendDocumentMessage from "../../features/SendMessage/SendDocumentMessage/SendDocumentMessage";
import SendPhotoMessage from "../../features/SendMessage/SendPhotoMessage/SendPhotoMessage";
import SendTextMessage from "../../features/SendMessage/SendTextMessage/SendTextMessage";
import Messages from "../Messages/Messages";
import "./style.scss";

interface Props {
  chat?: Chat;
}

const BoxChatBody: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [chat, setChat] = useState<Chat | undefined>();
  const [messages, setMessages] = useState<Message[]>();
  const [openFormSendContact, setOpenFormSendContact] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasReachMax, setHasReachMax] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replyMessage, setReplyMessage] = useState<Message>();
  const [isReplyMessage, setIsReplyMessage] = useState(false);
  const [editMessage, setEditMessage] = useState<Message>();
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [hasPinMessage, setHasPinMessage] = useState(false);

  useEffect(() => {
    setChat(props.chat);
    if (
      props.chat?.pinnedMessageId !== undefined &&
      props.chat?.pinnedMessageId.length > 0
    ) {
      setHasPinMessage(true);
    } else {
      setHasPinMessage(false);
    }
  }, [props.chat]);

  useEffect(() => {
    if (chat) {
      setMessages(vchat.chatMessages(chat.id).reverse());
    }
  }, [chat, vchat]);

  useEffect(() => {
    vchat.on("message.received", (message) => {
      if (chat) {
        setMessages(vchat.chatMessages(chat.id).reverse());
      }
    });
  }, [chat, vchat]);

  const handleClickOpenFormSendContact = () => {
    setOpenFormSendContact(true);
  };

  const handleCloseFormSendContact = () => {
    setOpenFormSendContact(false);
  };

  const onScroll = (e: any) => {
    const winScroll = e.target.scrollTop;
    const height = e.target.scrollHeight - e.target.clientHeight;

    if (-winScroll === height - 1) {
      if (chat) {
        if (!vchat.hasReachedMax(chat.id)) {
          vchat.loadMoreChatMessages(chat.id).then((data) => {
            data.forEach((message) => {
              setMessages((pre: any) => [...pre, message]);
            });
            setLoading(false);
          });
          if (vchat.chatMessagesLoading(chat.id)) {
            setLoading(true);
          } else {
            setLoading(false);
          }
        } else {
          setHasReachMax(true);
          setLoading(false);
        }
      }
    }
  };

  const HandleReplyMessage = (value: Message) => {
    setReplyMessage(value);
    setIsReplyMessage(true);
  };

  const closeReplyMessage = () => {
    setIsReplyMessage(false);
  };

  const handleEditMessage = (value: Message) => {
    setEditMessage(value);
    setIsEditMessage(true);
  };

  const closeEditMessage = () => {
    setIsEditMessage(false);
  };

  return (
    <Fragment>
      <div
        className={`${"body"} ${hasPinMessage ? "body-active" : ""}`}
        onScroll={onScroll}
      >
        {hasReachMax && (
          <div className="reachMax">Không còn tin nhắn cũ hơn</div>
        )}

        {loading && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}

        {messages?.map((data) => {
          return (
            <Messages
              key={data.id}
              message={data}
              chat={chat}
              replyMessage={HandleReplyMessage}
              editMessage={handleEditMessage}
            />
          );
        })}
      </div>

      <div className="footer">
        <div className={`${"reply"} ${isReplyMessage ? "reply-active" : ""}`}>
          <ReplyMessage
            closeReplyMessage={closeReplyMessage}
            message={replyMessage}
          />
        </div>
        <div className={`${"edit"} ${isEditMessage ? "edit-active" : ""}`}>
          <EditMessage
            closeEditMessage={closeEditMessage}
            message={editMessage}
          />
        </div>
        <div className="icon">
          <i className="fas fa-image">
            <SendPhotoMessage
              chat={chat}
              replyMessage={replyMessage}
              closeReplyMessage={closeReplyMessage}
            />
          </i>
          <i className="fas fa-link">
            <SendDocumentMessage
              chat={chat}
              replyMessage={replyMessage}
              closeReplyMessage={closeReplyMessage}
            />
          </i>
          <i
            className="far fa-address-card"
            onClick={handleClickOpenFormSendContact}
          ></i>
        </div>
        <div className="chat_input">
          <SendTextMessage
            chat={chat}
            replyMessage={replyMessage}
            closeReplyMessage={closeReplyMessage}
            editMessage={editMessage}
            closeEditMessage={closeEditMessage}
          />
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={openFormSendContact}
        onClose={handleCloseFormSendContact}
        aria-labelledby="responsive-dialog-title"
      >
        <SendContactMessage
          closeFormSendContact={handleCloseFormSendContact}
          chat={chat}
        />
      </Dialog>
    </Fragment>
  );
};

export default BoxChatBody;
