import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Message } from "vnpt-chat";

interface Props {
  message: Message;
}

const DraftMessagePhoto: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<Message>();
  const [messageUrl, setMessageUrl] = useState<string>();

  useEffect(() => {
    setMessage(props.message);
    props.message.loadPhoto();

    if (props.message) {
      setMessageUrl(props.message.photoUrl);
    }
  }, [props.message, message?.photoUrl, message]);

  return (
    <>
      <div className="message_photo">
        <img src={messageUrl} alt="hinhanh" />
      </div>
      <div className="message_time">
        <LinearProgress />
        <p>Đang gửi</p>
      </div>
    </>
  );
};

export default DraftMessagePhoto;
