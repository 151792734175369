import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VNPTChat, { Contact } from "vnpt-chat";
import { removeListUser } from "../listUserSlice";

interface Props {
  contact: Contact;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const ContactAdded: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [contact, setContact] = useState<Contact>();

  const [user, setUser] = useState(props.contact.user);

  if (!user) {
    props.contact.loadUser().then(setUser);
  }

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  const firstLetter = contact?.displayName?.slice(0, 1) || "u";

  const dispatch = useDispatch();

  const removeContactHandler = (event: any) => {
    const action = removeListUser({
      idNeedToRemove: event.currentTarget.attributes["data-id"].value,
    });
    dispatch(action);
  };

  // console.log(contact);

  return (
    <div className="contact_added">
      <div className="contact_added_avatar">
        <Avatar
          className={classes.avatar_user}
          style={{ backgroundColor: `${user?.avatarColor}` }}
        >
          {firstLetter}
        </Avatar>
      </div>
      <div className="contact_added_name">{contact?.displayName}</div>
      <div
        className="contact_added_iconCheck"
        data-id={user?.id}
        onClick={removeContactHandler}
      >
        <ClearIcon fontSize="small" />
      </div>
    </div>
  );
};

export default ContactAdded;
