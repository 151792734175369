import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { User } from "vnpt-chat";

interface Props {
  closeFormAddContact: (value: boolean) => void;
  user?: User;
}

const useStyles = makeStyles((theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
  context: {
    color: theme.palette.text.primary,
    width: "300px",
  },
  input: {
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const AddContact: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();
  const [user, setUser] = useState<User>();
  const [enteredDisplayName, setEnteredDisplayName] = useState();

  const handleClose = () => {
    props.closeFormAddContact(false);
  };

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  const firstLetter = user?.displayName?.slice(0, 1) || "u";

  const inputHandler = (event: any) => {
    setEnteredDisplayName(event.target.value);
  };

  const addContactHandler = () => {
    if (user) {
      vchat.createContact(user.id, enteredDisplayName || user.displayName);
    }
    props.closeFormAddContact(true);
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Thêm Liên Hệ</DialogTitle>
      <DialogContent className={classes.context}>
        <div className="user_add">
          <div className="user_add_avatar">
            <Avatar
              className={classes.avatar_user}
              style={{ backgroundColor: `${user?.avatarColor}` }}
            >
              {firstLetter}
            </Avatar>
          </div>
          <div className="user_add_name">{user?.displayName}</div>
        </div>
        <TextField
          className={classes.input}
          id="standard-textarea"
          label="Tên liên hệ"
          placeholder="Nhập tên liên hệ..."
          autoFocus
          fullWidth
          onChange={inputHandler}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.margin}
          onClick={handleClose}
        >
          Đóng
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.margin}
          onClick={addContactHandler}
        >
          Thêm
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AddContact;
