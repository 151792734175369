import { createSlice } from "@reduxjs/toolkit";

const listUserSlice = createSlice({
  name: "listUserAdded",

  initialState: {
    listUser: [] as Array<Object>,
    listUserId: [] as Array<string>,
  },

  reducers: {
    addListUser(state, action) {
      const newUser = action.payload;

      const index = state.listUser.findIndex(
        (x: any) => x.user.id === newUser.user.id
      );

      if (index < 0) {
        state.listUser.push(newUser);
        state.listUserId.push(newUser.user.id);
      }
    },

    removeListUser(state, action) {
      const idNeedToRemove = action.payload.idNeedToRemove;
      state.listUser = state.listUser.filter(
        (x: any) => x.user.id !== idNeedToRemove
      );
      state.listUserId = state.listUserId.filter(
        (x: any) => x !== idNeedToRemove
      );
    },
  },
});

const { actions, reducer } = listUserSlice;
export const { addListUser, removeListUser } = actions;
export default reducer;
