import {
  Avatar,
  Dialog,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import classnames from "classnames";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import VNPTChat, { Chat, Contact, Message, User } from "vnpt-chat";
import FormDeleteMessage from "../../features/DeleteMessage/FormDeleteMessage";
import FormForwardMessage from "../../features/ForwardMessage/FormForwardMessage";
import DraftMessageContact from "./DraftMessageContact/DraftMessageContact";
import DraftMessageDocument from "./DraftMessageDocument/DraftMessageDocument";
import DraftMessageForward from "./DraftMessageForward/DraftMessageForward";
import DraftMessagePhoto from "./DraftMessagePhoto/DraftMessagePhoto";
import DraftMessageText from "./DraftMessageText/DraftMessageText";
import MessageContact from "./MessageContact/MessageContact";
import MessageDeleted from "./MessageDeleted/MessageDeleted";
import MessageDocument from "./MessageDocument/MessageDocument";
import MessageForward from "./MessageForward/MessageForward";
import MessagePhoto from "./MessagePhoto/MessagePhoto";
import MessageSystem from "./MessageSystem/MessageSystem";
import MessageText from "./MessageText/MessageText";
import ReplyMessageText from "./ReplyMessageText/ReplyMessageText";
import ReplyMessagePhoto from "./ReplyMessagePhoto/ReplyMessagePhoto";
import ReplyMessageDocument from "./ReplyMessageDocument/ReplyMessageDocument";
import "./styles.scss";
import ReplyMessageContact from "./ReplyMessageContact/ReplyMessageContact";

interface Props {
  message: Message;
  chat?: Chat;
  replyMessage: (value: Message) => void;
  editMessage: (value: Message) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Messages: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const theme = useTheme();
  const [message, setMessage] = useState<Message>(props.message);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFormDeleteMessage, setOpenFormDeleteMessage] = useState(false);
  const [openFormForwardMessage, setOpenFormForwardMessage] = useState(false);
  const [user, setUser] = useState<User>();
  const [myself, setMyself] = useState<User>();
  const [sender, setSender] = useState<Contact>();
  const [chat, setChat] = useState<Chat>();
  const [replyMessage, setReplyMessage] = useState<Message>();

  useEffect(() => {
    setMessage(props.message);
    setChat(props.chat);
  }, [props.chat, props.message]);

  useEffect(() => {
    vchat.getProfile().then(setMyself);
  }, [vchat]);

  // vchat.on("message.deleted", (mess) => {
  //   if (mess.id === message.id) {
  //     setMessage(mess);
  //   }
  // });

  const time = moment(message?.createdAt);

  const handleClickOpenFormDeleteMessage = () => {
    setOpenFormDeleteMessage(true);
  };

  const handleCloseFormDeleteMessage = () => {
    setOpenFormDeleteMessage(false);
  };

  const handleClickOpenFormForwardMessage = () => {
    setOpenFormForwardMessage(true);
  };

  const handleCloseFormForwardMessage = () => {
    setOpenFormForwardMessage(false);
  };

  useEffect(() => {
    if (message.replyTo) {
      setReplyMessage(vchat.message(message.replyTo));
    }
  }, [message.replyTo, vchat]);

  useEffect(() => {
    if (message.senderId) {
      vchat.getUser(message.senderId).then((value) => {
        setUser(value);
      });
    }
    if (replyMessage && replyMessage.senderId) {
      vchat.getUser(replyMessage.senderId).then((value) => {
        setUser(value);
      });
    }
  }, [message.senderId, replyMessage, vchat]);

  useEffect(() => {
    if (user) {
      setSender(vchat.contact(user.id));
    }
  }, [user, vchat]);

  const firstLetter =
    sender?.displayName.slice(0, 1) || user?.displayName.slice(0, 1) || "u";

  const HandleReplyMessage = () => {
    props.replyMessage(message);
  };

  const handlePinMessage = () => {
    if (chat && message.id) {
      vchat.pinMessage(chat.id, message.id);
    }
  };

  const handleUnPinMessage = () => {
    if (chat && message.id) {
      vchat.unpinMessage(chat.id, message.id);
    }
  };

  const handleEditMessage = () => {
    props.editMessage(message);
  };

  return (
    <Fragment>
      <div
        className={classnames({
          list_message_system: true,
          list_message_owner: message?.isOwner && !message?.isSystemMessage,
          list_message_other: !message?.isOwner && !message?.isSystemMessage,
        })}
      >
        {chat?.isGroup && !message.isOwner && !message.isSystemMessage && (
          <div className="message-avatar-sender">
            <Avatar
              className={classes.member}
              style={{ backgroundColor: `${user?.avatarColor}` }}
            >
              {firstLetter}
            </Avatar>
          </div>
        )}

        <div className="message">
          {chat?.isGroup && !message.isOwner && !message.isSystemMessage && (
            <div className="message-name-sender">
              {sender?.displayName || user?.displayName}
            </div>
          )}

          {message.replyTo && replyMessage?.isTextMessage && (
            <ReplyMessageText
              replyMessage={replyMessage}
              user={user}
              sender={sender}
            />
          )}

          {message.replyTo && replyMessage?.isPhotoMessage && (
            <ReplyMessagePhoto
              replyMessage={replyMessage}
              user={user}
              sender={sender}
            />
          )}

          {message.replyTo && replyMessage?.isDocumentMessage && (
            <ReplyMessageDocument
              replyMessage={replyMessage}
              user={user}
              sender={sender}
            />
          )}

          {message.replyTo && replyMessage?.isContactMessage && (
            <ReplyMessageContact
              replyMessage={replyMessage}
              user={user}
              sender={sender}
            />
          )}

          {message.isDraftTextMessage && <DraftMessageText message={message} />}
          {message.isDraftPhotoMessage && (
            <DraftMessagePhoto message={message} />
          )}
          {message.isDraftDocumentMessage && (
            <DraftMessageDocument message={message} />
          )}
          {message.isDraftContactMessage && (
            <DraftMessageContact message={message} />
          )}
          {message.isDraftForwardMessage && (
            <DraftMessageForward message={message} />
          )}

          {message?.isTextMessage && <MessageText message={message} />}
          {message?.isPhotoMessage && <MessagePhoto message={message} />}
          {message?.isDocumentMessage && <MessageDocument message={message} />}
          {message?.isDeletedMessage && <MessageDeleted message={message} />}
          {message?.isForwardMessage && <MessageForward message={message} />}
          {message?.isSystemMessage && <MessageSystem message={message} />}
          {message?.isContactMessage && <MessageContact message={message} />}
          {chat?.pinnedMessageId.map((idPinMessage) => {
            return message.id === idPinMessage ? (
              <div className="icon-pin" key={idPinMessage}>
                <i className="fas fa-thumbtack"></i>
              </div>
            ) : (
              ""
            );
          })}

          {message?.isSystemMessage ||
          message.isDraftTextMessage ||
          message.isDraftPhotoMessage ||
          message.isDraftContactMessage ||
          message.isDraftDocumentMessage ||
          message.isDraftForwardMessage ? (
            ""
          ) : (
            <div className="message_time">
              <Moment format="HH:mm">{time}</Moment>
            </div>
          )}

          {message.isSystemMessage ||
            (!message.isDeletedMessage && (
              <div className="container_dropdown">
                <div className="dropdown_message">
                  <ul>
                    <li onClick={HandleReplyMessage}>
                      <i className="fas fa-reply"></i>
                      <p>Trả lời</p>
                    </li>
                    <li onClick={handleClickOpenFormForwardMessage}>
                      <i className="fas fa-share"></i>
                      <p>Chuyển tiếp</p>
                    </li>

                    {chat?.pinnedMessageId.map((idPinMessage) => {
                      return (
                        message.id === idPinMessage && (
                          <li onClick={handleUnPinMessage} key={idPinMessage}>
                            <i className="fas fa-thumbtack"></i>
                            <p>Gỡ ghim</p>
                          </li>
                        )
                      );
                    })}
                    {chat?.pinnedMessageId.findIndex(
                      (x) => x === message.id
                    ) === -1 && (
                      <li onClick={handlePinMessage}>
                        <i className="fas fa-thumbtack"></i>
                        <p>Ghim</p>
                      </li>
                    )}

                    {message.isTextMessage ? (
                      message.senderId === myself?.id ? (
                        <li onClick={handleEditMessage}>
                          <i className="far fa-edit"></i>
                          <p>Chỉnh sửa</p>
                        </li>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    <li onClick={handleClickOpenFormDeleteMessage}>
                      <i className="far fa-trash-alt"></i>
                      <p>Xóa</p>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={openFormDeleteMessage}
        onClose={handleCloseFormDeleteMessage}
        aria-labelledby="responsive-dialog-title"
      >
        <FormDeleteMessage
          closeFormDeleteMessage={handleCloseFormDeleteMessage}
          message={message}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openFormForwardMessage}
        onClose={handleCloseFormForwardMessage}
        aria-labelledby="responsive-dialog-title"
      >
        <FormForwardMessage
          closeFormForwardMessage={handleCloseFormForwardMessage}
          message={message}
        />
      </Dialog>
    </Fragment>
  );
};

export default Messages;
