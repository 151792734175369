import { Avatar, Dialog, makeStyles } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { User } from "vnpt-chat";
import AddContact from "../AddContact";

interface Props {
  user: User;
  closeFormAddContact: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
}));

const ContactSearch: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [user, setUser] = useState<User>();
  const [openFormAddContact, setOpenFormAddContact] = useState(false);

  useEffect(() => {
    if (props.user) {
      vchat.getUser(props.user.id).then(setUser);
    }
  }, [props.user, vchat]);

  const firstLetter = user?.displayName?.slice(0, 1) || "u";

  // console.log(listFriend);

  const handleOpenFormAddContact = () => {
    setOpenFormAddContact(true);
  };
  const closeFormAddContact = (value: boolean) => {
    setOpenFormAddContact(false);
    if (value) {
      props.closeFormAddContact(false);
    }
  };

  return (
    <Fragment>
      <div className={`${"user"} `} onClick={handleOpenFormAddContact}>
        <div className="user_avatar">
          <Avatar
            className={classes.avatar_user}
            style={{ backgroundColor: `${user?.avatarColor}` }}
          >
            {firstLetter}
          </Avatar>
        </div>
        <div className="user_infor">
          <div className="user_infor_name">{user?.displayName}</div>
        </div>
        <div className="btn_add"></div>
      </div>

      <Dialog
        open={openFormAddContact}
        onClose={closeFormAddContact}
        aria-labelledby="form-dialog-title"
      >
        <AddContact closeFormAddContact={closeFormAddContact} user={user} />
      </Dialog>
    </Fragment>
  );
};

export default ContactSearch;
