import { Avatar, Button, Typography } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../../components/form-control/InputField/InputField";

interface Props {
  onSubmit: (value: any) => void;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "0 auto",
    backgroundColor: "red",
  },
  submit: {
    margin: "10px 0",
  },
}));

const LoginForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const form = useForm({
    defaultValues: {
      username: "",
      email: "",
    },
  });

  const handleSubmit = (value: any) => {
    const { onSubmit } = props;
    if (onSubmit) {
      onSubmit(value);
    }

    form.reset();
  };

  return (
    <div>
      <Avatar className={classes.avatar}>
        <LockOutlined></LockOutlined>
      </Avatar>

      <Typography component="h3" variant="h5">
        Đăng nhập
      </Typography>

      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <InputField
          name="username"
          label="Tên đăng nhập"
          form={form}
        ></InputField>
        <InputField name="email" label="Tên hiển thị" form={form}></InputField>
        <Button
          type="submit"
          className={classes.submit}
          variant="contained"
          color="primary"
          fullWidth
        >
          Đăng nhập
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
