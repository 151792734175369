import { LinearProgress } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Message, User } from "vnpt-chat";
import MessageContact from "../MessageContact/MessageContact";
import MessageDocument from "../MessageDocument/MessageDocument";
import MessagePhoto from "../MessagePhoto/MessagePhoto";
import MessageText from "../MessageText/MessageText";

interface Props {
  message: Message;
}

const DraftMessageForward: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>();
  const [forwardMessage, setForwardMessage] = useState<Message>();
  const [sender, setSender] = useState<User>();

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  useEffect(() => {
    if (message) {
      setForwardMessage(message.forwardedMessage);
    }
  }, [message, vchat]);

  useEffect(() => {
    if (forwardMessage?.senderId) {
      vchat.getUser(forwardMessage.senderId).then(setSender);
    }
  }, [forwardMessage?.senderId, vchat]);

  return (
    <Fragment>
      <div className="message_forward">
        <div className="message_forward_icon">
          <i className="fas fa-forward"></i> Chuyển tiếp tin nhắn từ{" "}
          <b>{sender?.displayName}</b>
        </div>
        <div className="message_forward_content">
          {forwardMessage?.type === "text" && (
            <MessageText message={forwardMessage} />
          )}
          {forwardMessage?.type === "photo" && (
            <MessagePhoto message={forwardMessage} />
          )}
          {forwardMessage?.type === "file" && (
            <MessageDocument message={forwardMessage} />
          )}
          {forwardMessage?.type === "contact" && (
            <MessageContact message={forwardMessage} />
          )}
        </div>
      </div>
      <div className="message_time">
        <LinearProgress />
        <p>Đang gửi</p>
      </div>
    </Fragment>
  );
};

export default DraftMessageForward;
