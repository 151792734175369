import { InputAdornment, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { deepOrange } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import React, { Fragment, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import VNPTChat, { Contact, User } from "vnpt-chat";
import ContactFriend from "./Contact/Contact";
import ContactAdded from "./ContactAdded/ContactAdded";
import { removeListUser } from "./listUserSlice";
import "./style.scss";

interface Props {
  closeFormAddChatGroup: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const AddChatGroup: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const dispatch = useDispatch();
  const [nameGroupChat, setNameGroupChat] = useState<string>();
  const [list, setList] = useState<Contact[]>();

  const [listContact, setListContact] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  vchat.on("contactsList.updated", (chats) => {
    setListContact(vchat.contacts);
  });

  const ListUserRedux = useSelector((state: RootStateOrAny) => state.listUser); //Lấy từ redux

  useEffect(() => {
    const result = listContact.filter(
      ({ userId: id1 }) =>
        !ListUserRedux.listUser.some((user: any) => id1 === user.user.id)
    );

    setList(result);
  }, [ListUserRedux.listUser, listContact]);

  const handleClose = () => {
    props.closeFormAddChatGroup(false);
    ListUserRedux.listUser.map((user: any) => {
      const action = removeListUser({
        idNeedToRemove: user.user.id,
      });
      dispatch(action);
    });
  };

  const changGroupChatNameHandler = (event: any) => {
    setNameGroupChat(event.target.value);
  };

  const findUserHandler = (event: any) => {
    console.log(vchat.findUsers(event.target.value));
  };

  const createGroupChatHandler = () => {
    vchat.createGroupChat(nameGroupChat || "", ListUserRedux.listUserId);
    setNameGroupChat("");
    props.closeFormAddChatGroup(false);
    ListUserRedux.listUser.map((user: any) => {
      const action = removeListUser({
        idNeedToRemove: user.user.id,
      });
      dispatch(action);
    });
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Tạo Nhóm Chat</DialogTitle>
      <div className="body_addchat">
        <div className="chat_name">
          <div className="icon_namechat">
            <i className="fas fa-camera"></i>
          </div>
          <div className="input_namechat">
            <TextField
              id="standard-textarea"
              label="Tên Nhóm"
              placeholder="Hãy nhập tên nhóm..."
              autoFocus
              fullWidth
              required
              onChange={changGroupChatNameHandler}
              value={nameGroupChat}
            />
          </div>
        </div>
        <div className="addContact">
          <div className="input_addContact">
            <TextField
              size="small"
              id="outlined-textarea"
              label="Tên Người Dùng"
              placeholder="Hãy nhập tên hoặc username"
              variant="outlined"
              onChange={findUserHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        </div>
        <div className="list_contacts">
          <div className="contacts">
            {list?.map((contact) => {
              return <ContactFriend contact={contact} key={contact.userId} />;
            })}
          </div>
          <div className="contacts_add">
            {ListUserRedux.listUser.map((contact: Contact) => {
              return <ContactAdded key={contact.user?.id} contact={contact} />;
            })}
          </div>
        </div>
      </div>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          onClick={createGroupChatHandler}
        >
          Tạo
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.button}
          onClick={handleClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AddChatGroup;
