import React, { Fragment, useEffect, useState } from "react";
import { Message } from "vnpt-chat";
import "./style.scss";
const prettyBytes = require("pretty-bytes");

interface Props {
  message: Message;
}
const BoxChatFile: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<Message>();
  const [sizeDocument, setSizeDocument] = useState();

  useEffect(() => {
    setMessage(props.message);
    setSizeDocument(prettyBytes(props.message?.document.fileSize ?? 0));
  }, [props.message, message]);

  return (
    <div className="document">
      <div className="icon_document">
        <i className="fas fa-file"></i>
      </div>
      <div className="infor_document">
        <div className="name_document">{message?.document.fileName}</div>
        <div className="size_document">{sizeDocument || "15MB"}</div>
      </div>
    </div>
  );
};

export default BoxChatFile;
