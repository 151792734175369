import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";
import { Chat, User } from "vnpt-chat";

interface Props {
  chat: Chat;
  sendChatToForwardMessage: (
    value1: Chat,
    value2: string,
    value3: boolean
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar_user: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const ChatForwardMessage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState<User>();
  const [active, setActive] = useState(true);

  const [chat] = useState(() => {
    const list = props.chat;
    return list;
  });

  useEffect(() => {
    if (chat.isIndividual) {
      setUser(chat.userPartner);
    }
    if (!user) {
      chat.loadPartner().then(setUser);
    }
  }, [chat, user]);

  const firstLetter =
    chat?.title?.slice(0, 1) || user?.displayName?.slice(0, 1) || "u";

  const sendChatToForwardMessage = () => {
    setActive(!active);

    if (chat.isIndividual) {
      if (user) {
        props.sendChatToForwardMessage(chat, user.id, active);
      }
    } else {
      props.sendChatToForwardMessage(chat, chat.id, active);
    }
  };

  return (
    <div className="chat" onClick={sendChatToForwardMessage}>
      <div
        className={`${"chat_checkIcon"} ${
          !active ? "chat_checkIcon_active" : ""
        }`}
      >
        <CheckIcon fontSize="small" />
      </div>
      <div className="chat_avatar">
        <Avatar
          className={classes.avatar_user}
          style={{
            backgroundColor: `${chat.avatarColor || user?.avatarColor}`,
          }}
        >
          {firstLetter}
        </Avatar>
      </div>
      <div className="chat_name">{chat.title || user?.displayName}</div>
    </div>
  );
};

export default ChatForwardMessage;
