import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Fragment } from "react";
import VNPTChat, { Chat } from "vnpt-chat";

interface Props {
  closeRemoveChatMember: (value: boolean) => void;
  chat: Chat;
  member: any;
}

const RemoveChatMember: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;

  const handleClose = () => {
    props.closeRemoveChatMember(false);
  };

  const removeChatMemberHandler = () => {
    vchat.removeChatMember(props.chat.id, props.member.userId);
    props.closeRemoveChatMember(false);
  };

  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">{"Xác nhận"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bạn có chắc muốn xóa thành viên này ra khỏi nhóm?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Hủy
        </Button>
        <Button onClick={removeChatMemberHandler} color="primary">
          Đồng ý
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default RemoveChatMember;
