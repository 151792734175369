import { Dialog } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Contact, User } from "vnpt-chat";
import AddContact from "../../features/AddContact/FormAddContact";
import Friend from "./Contact";
import "./style.scss";

interface Props {
  sendContactUserFromPhoneBookToHome: (
    value1: Contact,
    value2: User | undefined
  ) => void;
  isOpenBoxChat: boolean;
  openListChatGroup: (value: boolean) => void;
  isOpenListGroupChatByClick: boolean;
}

const PhoneBook: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [openFormAddContact, setOpenFormAddContact] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [listFriend, setListFriend] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  vchat.on("contactsList.updated", (chats) => {
    setListFriend(vchat.contacts);
  });

  const handleOpenFormAddContact = () => {
    setOpenFormAddContact(true);
  };
  const closeFormAddContact = (value: boolean) => {
    setOpenFormAddContact(false);
  };

  const openBoxChat = (value1: Contact, value2: User | undefined) => {
    props.sendContactUserFromPhoneBookToHome(value1, value2);
    setIsActive(true);
  };

  useEffect(() => {
    if (!props.isOpenBoxChat) {
      setIsActive(false);
    }
  }, [props.isOpenBoxChat]);

  useEffect(() => {
    if (!props.isOpenListGroupChatByClick) {
      setIsActive(false);
    }
  }, [props.isOpenListGroupChatByClick]);

  const openListGroup = () => {
    props.openListChatGroup(true);
    setIsActive(true);
  };

  const findUserHandler = (event: any) => {};

  return (
    <Fragment>
      <div className={`${"center"} ${isActive ? "activeChat" : ""}`}>
        <div className="center_top">
          <div className="search">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Tìm kiếm"
              onChange={findUserHandler}
            />
          </div>
          <div className="icon">
            <i
              className="fas fa-user-plus"
              onClick={handleOpenFormAddContact}
            ></i>
          </div>
        </div>

        <div className="group-chat" onClick={openListGroup}>
          <div className="group-chat-icon">
            <i className="fas fa-users"></i>
          </div>
          <div className="group-chat-title">Danh sách nhóm</div>
        </div>

        <div className="list_friend">
          <p className="list_friend_title">Bạn bè</p>
          {listFriend?.map((contact) => {
            return (
              <Friend
                key={contact.userId}
                contact={contact}
                sendContactUserFromContactToPhoneBook={openBoxChat}
              />
            );
          })}
        </div>
      </div>

      <Dialog
        open={openFormAddContact}
        onClose={closeFormAddContact}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <AddContact closeFormAddContact={closeFormAddContact} />
      </Dialog>
    </Fragment>
  );
};

export default PhoneBook;
