"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncQueue = void 0;
class AsyncQueue {
    constructor() {
        this.pendingPromise = false;
        this.items = new Map();
        this.priorities = new Set();
    }
    get size() {
        let total = 0;
        this.items.forEach((list) => {
            total += list.length;
        });
        return total;
    }
    enqueue(action, options = { priority: 1 }) {
        return new Promise((resolve, reject) => {
            var _a;
            if (!this.priorities.has(options.priority)) {
                this.priorities.add(options.priority);
            }
            if (!this.items.has(options.priority)) {
                this.items.set(options.priority, []);
            }
            (_a = this.items.get(options.priority)) === null || _a === void 0 ? void 0 : _a.push({ action, resolve, reject });
            this.dequeue();
        });
    }
    getMaxPriority() {
        if (this.priorities.size == 0)
            return null;
        return Math.max(...Array.from(this.priorities));
    }
    getNextQueueItem() {
        var _a;
        const priority = this.getMaxPriority();
        if (!priority)
            return null;
        const item = (_a = this.items.get(priority)) === null || _a === void 0 ? void 0 : _a.shift();
        if (!item) {
            this.priorities.delete(priority);
            return this.getNextQueueItem();
        }
        return item;
    }
    dequeue() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.pendingPromise)
                return;
            const item = this.getNextQueueItem();
            if (!item)
                return;
            try {
                this.pendingPromise = true;
                let payload = yield item.action();
                this.pendingPromise = false;
                item.resolve(payload);
            }
            catch (ex) {
                this.pendingPromise = false;
                item.reject(ex);
            }
            finally {
                this.dequeue();
            }
            return item;
        });
    }
}
exports.AsyncQueue = AsyncQueue;
