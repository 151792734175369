import { LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FileIcon } from "react-file-icon";
import VNPTChat, { Message } from "vnpt-chat";

interface Props {
  message: Message;
}

const DraftMessageDocument: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);
  const [sizeDocument, setSizeDocument] = useState<any>(0);
  const [uploaded, setUploaded] = useState<any>(0);
  const [percent, setPercent] = useState<any>(0);

  useEffect(() => {
    setMessage(props.message);
    setSizeDocument(props.message.file?.size);
  }, [props.message]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (message.fileUpload) {
        setUploaded(vchat.fileUpload(message.fileUpload?.uploadId)?.uploaded);
      }
      setPercent(((uploaded * 100) / sizeDocument).toFixed());
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [message.fileUpload, sizeDocument, uploaded, vchat]);

  return (
    <>
      <div className="message_file">
        <div className="icon_file">
          <FileIcon />
        </div>
        <div className="message_file_right">
          <div className="name_file">{props.message.file?.name}</div>
          <div className="size_file">{percent}%</div>
        </div>
      </div>
      <div className="message_time">
        <LinearProgress variant="determinate" value={percent} />
      </div>
      <div className="message_time">Đang gửi</div>
    </>
  );
};

export default DraftMessageDocument;
