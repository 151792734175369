import React, { Fragment, useState } from "react";
import VNPTChat, { Message } from "vnpt-chat";

interface Props {
  message: Message;
}
const MessageText: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);

  vchat.on("message.edited", (mess) => {
    if (mess.id === message.id) {
      setMessage(mess);
    }
  });

  return (
    <Fragment>
      <div className="message_text">{message.text}</div>
    </Fragment>
  );
};

export default MessageText;
