import { TextField } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

interface Value {
  form: any;
  name: string;
  label: string;
}

const InputField: React.FC<Value> = (props) => {
  const { form, name, label } = props;
  return (
    <Controller
      name={name}
      control={form.control}
      as={TextField}
      label={label}
      fullWidth
      variant="outlined"
      margin="normal"
    />
  );
};

export default InputField;
