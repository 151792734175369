import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat } from "vnpt-chat";

interface Props {
  closeLeaveChat: (value: boolean) => void;
  chat?: Chat;
}

const LeaveChat: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [chat, setChat] = useState<Chat | undefined>();

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  const handleClose = () => {
    props.closeLeaveChat(false);
  };

  const leaveChatHandler = () => {
    if (chat) {
      vchat.leaveChat(chat.id);
    }
    props.closeLeaveChat(false);
  };

  return (
    <Fragment>
      <DialogTitle id="alert-dialog-title">
        {"Rời nhóm và xóa hội thoại"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Rời nhóm đồng thời sẽ xóa toàn bộ tin nhắn của nhóm đó. Bạn có muốn
          tiếp tục?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Không
        </Button>
        <Button onClick={leaveChatHandler} color="primary">
          Rời nhóm
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default LeaveChat;
