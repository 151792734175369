import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import VNPTChat from "vnpt-chat";
import "./App.css";
import Home from "./components/Home/Home";
import Notification from "./components/Notification/Notification";
import Login from "./features/Auth/component/Login/Login";

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  VNPTChat.configure({
    serverUrl: "vchat-ws.devitkv2.com:443",
    secure: true
  });

  useEffect(() => {
    if(firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      messaging
        .getToken({
          vapidKey:
            "BJkU1gM_jvAkuk57QBntUvUQ1ENBat_IgFRD2P7KfWnsQszW2taC9Xkfji4epwUiij2nvQO0F8FoWW1bOraPHjA",
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Token:", currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  }, []);


  return (
    <div className="App">
      {show ? (
        <Notification title={notification.title} body={notification.body} />
      ) : (
        <></>
      )}
      <Switch>
        <Route path="/signin" component={Login} exact></Route>
        <Route path="/" component={Home} exact></Route>
        <Route path="*" component={Login}></Route>
      </Switch>
    </div>
  );
}

export default App;
