import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Message, User } from "vnpt-chat";
import PinnedMessagePhoto from "./PinnnedMessagePhoto/PinnedMessagePhoto";

interface Props {
  idPinMessage: string;
}

const PinnedMessage: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [pinnedMessage, setPinnedMessage] = useState<Message>();
  const [sender, setSender] = useState<User>();

  useEffect(() => {
    setPinnedMessage(vchat.message(props.idPinMessage));
  }, [props.idPinMessage, vchat]);

  useEffect(() => {
    if (pinnedMessage && pinnedMessage.senderId) {
      vchat.getUser(pinnedMessage.senderId).then(setSender);
    }
  }, [pinnedMessage, vchat]);

  return (
    <Fragment>
      <div className="content-pinned-message">
        <div className="sender-pinned-message">{sender?.displayName}: </div>
        {pinnedMessage?.isTextMessage && pinnedMessage?.text}

        {pinnedMessage?.isPhotoMessage && (
          <PinnedMessagePhoto message={pinnedMessage} />
        )}

        {pinnedMessage?.isDocumentMessage && (
          <div className="pinned-message-file">
            <i className="far fa-file"></i>
            <div className="pinned-message-file-name">
              {pinnedMessage.document.fileName}
            </div>
          </div>
        )}

        {pinnedMessage?.isContactMessage && "Danh thiếp"}

        {pinnedMessage?.isForwardMessage &&
          pinnedMessage.forwardedMessage.isTextMessage &&
          pinnedMessage.forwardedMessage.text}

        {pinnedMessage?.isForwardMessage &&
          pinnedMessage.forwardedMessage.isPhotoMessage && (
            <PinnedMessagePhoto message={pinnedMessage} />
          )}

        {pinnedMessage?.isForwardMessage &&
          pinnedMessage.forwardedMessage.isDocumentMessage && (
            <div className="pinned-message-file">
              <i className="far fa-file"></i>
              <div className="pinned-message-file-name">
                {pinnedMessage.forwardedMessage.document.fileName}
              </div>
            </div>
          )}

        {pinnedMessage?.isForwardMessage &&
          pinnedMessage.forwardedMessage.isContactMessage &&
          "Danh thiếp"}
      </div>
    </Fragment>
  );
};

export default PinnedMessage;
