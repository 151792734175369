import React, { useEffect, useState } from "react";
import VNPTChat, { FileDownload, Message } from "vnpt-chat";

interface Props {
  message: Message;
}
const MessagePhoto: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const [message, setMessage] = useState<Message>(props.message);
  const [messageUrl, setMessageUrl] = useState<string>();
  const [documentDownload, setDocumentDownload] = useState<FileDownload>();

  useEffect(() => {
    setMessage(props.message);
    props.message.loadPhoto();

    if (message) {
      setMessageUrl(message.photoUrl);
    }
  }, [message, props.message]);

  useEffect(() => {
    vchat.on("fileDownload.new", (fileDownload) => {
      message.photo.forEach((item) => {
        if (item.fileId === fileDownload.fileId) {
          setDocumentDownload(fileDownload);
        }
      });
    });
  }, [message.photo, vchat]);

  useEffect(() => {
    if (documentDownload) {
      documentDownload.on("updated", () => {
        setMessageUrl(documentDownload.objectUrl);
        message.loadPhoto();
      });
    }
  }, [documentDownload, message]);

  return (
    <>
      <div className="message_photo">
        <img src={messageUrl} alt="hinhanh" />
        <p className="message_photo_text">{message.text ? message.text : ""}</p>
      </div>
    </>
  );
};

export default MessagePhoto;
