import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Contact, User } from "vnpt-chat";
import "../style.scss";

interface Props {
  user?: User;
  openBoxChatInfor: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatarBoxChat: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const HeaderBoxChatUser: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [user, setUser] = useState<User | undefined>();
  const [contact, setContact] = useState<Contact | undefined>();
  const [openBoxChatInfor, setOpenBoxChatInfor] = useState(true);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  vchat.on("contact.edited", (contact) => {
    setContact(contact);
  });

  const firstLetterUser =
    contact?.displayName?.slice(0, 1) || user?.displayName?.slice(0, 1) || "u";

  const handleOpenBoxChatInfor = () => {
    setOpenBoxChatInfor((x) => !x);
    props.openBoxChatInfor(openBoxChatInfor);
  };

  return (
    <div className="header">
      <div className="header_information">
        <div className="avatar" onClick={handleOpenBoxChatInfor}>
          <Avatar
            className={classes.avatarBoxChat}
            style={{
              backgroundColor: `${user?.avatarColor}`,
            }}
          >
            {firstLetterUser}
          </Avatar>
        </div>
        <div className="name_member">
          <p className="name">
            {contact?.displayName || user?.displayName || "unknown"}
          </p>
          <p
            className={`${"member"} ${
              user?.onlineState === "online" ? "online" : ""
            }`}
          >
            {user?.onlineState}
          </p>
        </div>
      </div>
      <div className="header_feature">
        <i className="far fa-address-card" onClick={handleOpenBoxChatInfor}></i>
      </div>
    </div>
  );
};

export default HeaderBoxChatUser;
