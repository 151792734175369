import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { Chat, Contact, User } from "vnpt-chat";
import LogOut from "../../features/LogOut/LogOut";
import BoxChat from "../BoxChat/BoxChat";
import ListChatGroup from "../ListChatGroup/ListChatGroup";
import ListRoom from "../ListRoom/ListRoom";
import PhoneBook from "../PhoneBook/PhoneBook";
import "./style.scss";

const Home: React.FC = (props) => {
  const local = localStorage.getItem("signin");
  const user = local && JSON.parse(local);

  const [openSettingDropdown, setOpenSettingDropdown] = useState(false);
  const [openFormLogOut, setOpenFormLogOut] = useState(false);
  const [openFormInformation, setOpenFormInformation] = useState(false);
  const [isListRoom, setIsListRoom] = useState(true);
  const [isPhoneBook, setIsPhoneBook] = useState(false);
  const [isWelcome, setIsWelcome] = useState(true);
  const [isOpenBoxChat, setIsOpenBoxChat] = useState(false);
  const [isOpenListGroupChat, setIsOpenListGroupChat] = useState(false);
  const [isOpenListGroupChatByClick, setIsOpenListGroupChatByClick] =
    useState(false);
  const [room, setRoom] = useState<Chat | undefined>();

  // đóng mở dropdown setting
  const openSettingDropdownHandler = () => {
    setOpenSettingDropdown((x) => !x);
  };

  //Đóng mở dialog logout
  const handleClickOpenFormLogOut = () => {
    setOpenFormLogOut(true);
  };
  const handleCloseFormLogOut = () => {
    setOpenFormLogOut(false);
  };

  //đóng mở dialog form Information
  const handleClickOpenFormInformation = () => {
    setOpenFormInformation(true);
  };
  const handleCloseFormInformation = () => {
    setOpenFormInformation(false);
  };

  //NHẤN NÚT CHAT
  const HandleOpenChat = () => {
    setIsListRoom(true);
    setIsPhoneBook(false);
    setIsWelcome(true);
    setIsOpenBoxChat(false);
    setIsOpenListGroupChat(false);
    setIsOpenListGroupChatByClick(false);
  };

  //NHẤN NÚT DANH BẠ
  const HandleOpenPhoneBook = () => {
    setIsListRoom(false);
    setIsPhoneBook(true);
    setIsWelcome(false);
    setIsOpenBoxChat(false);
    setIsOpenListGroupChat(true);
    setIsOpenListGroupChatByClick(false);
  };

  const handleOpenBoxChat = (chat: Chat) => {
    setRoom(chat);
    setIsOpenBoxChat(true);
    setIsWelcome(false);
  };
  const handleOpenBoxChatFromUser = (chat: Chat | undefined) => {
    setRoom(chat);
    setIsOpenBoxChat(true);
    setIsWelcome(false);
  };

  const openBoxChatFromContact = (
    value1: Contact,
    value2: User | undefined
  ) => {
    setIsListRoom(false);
    setIsPhoneBook(true);
    setIsWelcome(false);
    setIsOpenBoxChat(true);
    setRoom(value2?.individualChat);
    setIsOpenListGroupChat(false);
    setIsOpenListGroupChatByClick(false);
  };

  const openBoxChatFromListChatGroup = (value: Chat) => {
    setIsOpenListGroupChat(false);
    setIsListRoom(false);
    setIsPhoneBook(true);
    setIsWelcome(false);
    setIsOpenBoxChat(true);
    setRoom(value);
    setIsOpenListGroupChatByClick(true);
  };

  const openListGroupChat = () => {
    setIsListRoom(false);
    setIsPhoneBook(true);
    setIsWelcome(false);
    setIsOpenBoxChat(false);
    setIsOpenListGroupChat(true);
    setIsOpenListGroupChatByClick(true);
  };

  if (user) {
    // loginChat(user.username);
    return (
      <Fragment>
        <div className="body">
          <div className="left">
            <div className="user" onClick={handleClickOpenFormInformation}>
              <img
                src="https://media.istockphoto.com/vectors/man-face-icon-in-medical-mask-male-person-in-surgical-mask-people-vector-id1222731059"
                alt=""
              />
            </div>
            <div
              className={`${"chat"} ${isListRoom ? "active_toggle" : ""}`}
              onClick={HandleOpenChat}
            >
              <i className="fas fa-comment" title="Tin nhắn"></i>
            </div>
            <div
              className={`${"phonebook"} ${isPhoneBook ? "active_toggle" : ""}`}
              onClick={HandleOpenPhoneBook}
            >
              <i className="far fa-address-book" title="Danh bạ"></i>
            </div>
            <div className="setting" onClick={openSettingDropdownHandler}>
              <i className="fas fa-cog" title="Cài đặt"></i>
              {openSettingDropdown ? (
                <div className="setting_dropdown">
                  <div className="setting_detail">
                    <i className="fas fa-cog"></i>Cài đặt
                  </div>
                  <div className="logout" onClick={handleClickOpenFormLogOut}>
                    Đăng xuất
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {isListRoom && (
            <ListRoom
              onSendRoomFromListRoomToHome={handleOpenBoxChat}
              onSendChatUserFromListRoomToHome={handleOpenBoxChatFromUser}
              isOpenBoxChat={isOpenBoxChat}
            />
          )}
          {isPhoneBook && (
            <PhoneBook
              sendContactUserFromPhoneBookToHome={openBoxChatFromContact}
              isOpenBoxChat={isOpenBoxChat}
              openListChatGroup={openListGroupChat}
              isOpenListGroupChatByClick={isOpenListGroupChatByClick}
            />
          )}

          {isOpenBoxChat && <BoxChat chat={room} isActive={true} />}

          {isWelcome && <div className="welcome">Chào mừng</div>}

          {isOpenListGroupChat && (
            <ListChatGroup
              openChatGroup={openBoxChatFromListChatGroup}
              isOpenListGroupChatByClick={isOpenListGroupChatByClick}
            />
          )}
        </div>

        {/* form log out */}
        <Dialog
          open={openFormLogOut}
          onClose={handleCloseFormLogOut}
          aria-labelledby="form-dialog-title"
        >
          <LogOut closeFormLogOut={handleCloseFormLogOut} />
        </Dialog>

        {/* form information */}
        <Dialog
          open={openFormInformation}
          onClose={handleCloseFormInformation}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Thông tin cá nhân</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-read-only-input"
              label="Tên đăng nhập"
              defaultValue={user.username}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              id="outlined-read-only-input"
              label="Tên hiển thị"
              defaultValue={user.email}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFormInformation} color="primary">
              Đóng
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  } else {
    return <Redirect to="/signin" />;
  }
};

export default Home;
