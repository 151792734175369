import { InputAdornment, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import React, { Fragment, useState } from "react";
import VNPTChat, { User } from "vnpt-chat";
import ContactSearch from "./Contact/ContactSearch";
import "./style.scss";

interface Props {
  closeFormAddContact: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  context: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    width: "300px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const FormAddContact: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [listUserSearch, setListUserSearch] = useState<Array<User>>([]);
  const [listResult, setListResult] = useState<Array<User>>([]);

  const [listContact, setListContact] = useState(() => {
    const list = vchat.contacts;
    return list;
  });

  vchat.on("contactsList.updated", (chats) => {
    setListContact(vchat.contacts);
  });

  const handleClose = () => {
    props.closeFormAddContact(false);
  };

  const HandleInputValue = (event: any) => {
    vchat.findUsers(event.target.value).then(setListUserSearch);

    const result = listUserSearch.filter(
      ({ id: id1 }) => !listContact.some((user) => id1 === user.userId)
    );

    setListResult(result);
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Thêm Liên Hệ</DialogTitle>
      <DialogContent className={classes.context}>
        <TextField
          size="small"
          id="outlined-textarea"
          label="Tên Người Dùng"
          placeholder="Hãy nhập tên hoặc username"
          variant="outlined"
          onChange={HandleInputValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </DialogContent>
      <div className="result">
        {listResult.map((user, index) => {
          return (
            <ContactSearch
              user={user}
              key={user.id}
              closeFormAddContact={handleClose}
            />
          );
        })}
      </div>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.margin}
          onClick={handleClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default FormAddContact;
