import { Dialog, useMediaQuery, useTheme } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat } from "vnpt-chat";
import AddChatGroup from "../../features/AddChatGroup/AddChatGroup";
import ChatGroup from "./ChatGroup";
import ChatUser from "./ChatUser";
import "./style.scss";

interface Props {
  onSendRoomFromListRoomToHome: (value: Chat) => void;
  onSendChatUserFromListRoomToHome: (chat: Chat | undefined) => void;
  isOpenBoxChat: boolean;
}

const ListRoom: React.FC<Props> = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const vchat = VNPTChat.instance;
  const [openFormAddChatGroup, setOpenFormAddChatGroup] = useState(false);
  const [isActiveChat, setIsActiveChat] = useState(false);

  const [listRoom, setListRoom] = useState(() => {
    const list = vchat.chats;
    return list;
  });

  vchat.on("chatsList.updated", (chats) => {
    setListRoom(vchat.chats);
  });
  vchat.on("contactsList.updated", (contacts) => {
    setListRoom(vchat.chats);
  });

  const handleOpenBoxChat = (room: Chat) => {
    props.onSendRoomFromListRoomToHome(room);
    setIsActiveChat(true);
  };

  const handleOpenBoxChatFromUser = (chat: Chat | undefined) => {
    props.onSendChatUserFromListRoomToHome(chat);
    setIsActiveChat(true);
  };

  const handleOpenFormAddChatGroup = () => {
    setOpenFormAddChatGroup(true);
  };
  const closeFormAddChatGroup = (value: boolean) => {
    setOpenFormAddChatGroup(false);
  };

  useEffect(() => {
    if (!props.isOpenBoxChat) {
      setIsActiveChat(false);
    }
  }, [props.isOpenBoxChat]);

  return (
    <Fragment>
      <div className={`${"center"} ${isActiveChat ? "activeChat" : ""}`}>
        <div className="title_center_listRoom">
          <div className="title"> VNPT CHAT</div>
          <div className="title_center_listRoom_icon">
            <i
              className="fas fa-users"
              onClick={handleOpenFormAddChatGroup}
            ></i>
          </div>
        </div>
        <div className="listRoom">
          {listRoom
            ? listRoom.map((chat) => {
                return chat.isIndividual ? (
                  <ChatUser
                    key={chat.id}
                    chat={chat}
                    onSendChatUserToListRoomFromChatUser={
                      handleOpenBoxChatFromUser
                    }
                  />
                ) : (
                  <ChatGroup
                    key={chat.id}
                    room={chat}
                    onSendRoomToListRoomFromChatGroup={handleOpenBoxChat}
                  />
                );
              })
            : ""}
        </div>
      </div>
      <Dialog
        open={openFormAddChatGroup}
        onClose={closeFormAddChatGroup}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <AddChatGroup closeFormAddChatGroup={closeFormAddChatGroup} />
      </Dialog>
    </Fragment>
  );
};

export default ListRoom;
