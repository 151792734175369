import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Message, User } from "vnpt-chat";

interface Props {
  message: Message;
}
const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const MessageContact: React.FC<Props> = (props) => {
  const classes = useStyles();
  const vchat = VNPTChat.instance;
  const [messages] = useState<Message>(props.message);
  const firstLetter = messages.contact.displayName?.slice(0, 1) || "u";
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (messages.contact.userId) {
      vchat.getUser(messages.contact.userId).then(setUser);
    }
  }, [messages.contact.userId, vchat]);

  return (
    <div className="message_contact">
      <div className="contact_top">
        <div className="contact_avatar">
          <Avatar
            className={classes.member}
            style={{ backgroundColor: `${user?.avatarColor}` }}
          >
            {firstLetter}
          </Avatar>
        </div>
        <div className="contact_infor">
          <p className="contact_title">Danh thiếp</p>
          <p className="contact_name">
            {messages.contact.displayName || "unknown"}
          </p>
        </div>
      </div>
      <div className="contact_bottom">
        <p className="chat">NHẮN TIN</p>
      </div>
    </div>
  );
};

export default MessageContact;
