import listUserReduce from "../features/AddChatGroup/listUserSlice";
import listShareContactReduce from "../features/SendMessage/SendContactMessage/listContactSlice";
import listChatMemberReduce from "../features/AddChatMember/listUserSlice";
import { configureStore } from "@reduxjs/toolkit";

const rootReducer = {
  listUser: listUserReduce,
  listShareContact: listShareContactReduce,
  listChatMember: listChatMemberReduce,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (
    getDefaultMiddleware //khỏi bị lỗi anon serializeable
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
