import { Avatar, Dialog, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { Fragment, useEffect, useState } from "react";
import VNPTChat, { Chat, Contact, User } from "vnpt-chat";
import RemoveChatMember from "../../../../features/RemoveChatMember/RemoveChatMember";

interface Props {
  member: any;
  chat: Chat;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Member: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();
  const [user, setUser] = useState<User>();
  const [myself, setMyself] = useState<User>();
  const [contact, setContact] = useState<Contact>();
  const [open, setOpen] = useState(false);
  const [openFormRemoveChatMember, setOpenFormRemoveChatMember] =
    useState(false);

  useEffect(() => {
    if (props.member) {
      vchat.getUser(props.member.userId).then(setUser);
    }
  }, [props.member, vchat]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  useEffect(() => {
    vchat.getProfile().then(setMyself);
  }, [vchat]);

  const firstLetter =
    contact?.displayName?.slice(0, 1) || user?.displayName?.slice(0, 1) || "u";

  const openDropdown = () => {
    setOpen((x) => !x);
  };

  const openFormRemoveChatMemberHandler = () => {
    setOpenFormRemoveChatMember(true);
  };
  const closeFormRemoveChatMemberHandler = () => {
    setOpenFormRemoveChatMember(false);
  };

  return (
    <Fragment>
      <div className="boxchat_information_members_member">
        <Avatar
          className={classes.member}
          style={{ backgroundColor: `${user?.avatarColor}` }}
        >
          {firstLetter}
        </Avatar>

        <div className="boxchat_information_members_member_name_position">
          <p className="boxchat_information_members_member_name">
            {contact?.displayName || user?.displayName || "unknown"}
          </p>
          <p className="boxchat_information_members_member_position">
            {props.member.status === "creator" ? "Chủ phòng" : ""}
          </p>
        </div>

        <div
          className="boxchat_information_members_member_dropdown"
          onClick={openDropdown}
        >
          {props.chat.ownerId === user?.id ? (
            ""
          ) : myself?.id === user?.id ? (
            ""
          ) : (
            <MoreHorizIcon />
          )}

          {open && (
            <div className="dropdown_member">
              <div
                className="dropdown_member_outgroup"
                onClick={openFormRemoveChatMemberHandler}
              >
                <span>Mời ra khỏi nhóm</span>
              </div>
              <div className="dropdown_member_subcreator">
                <span>Thêm phó nhóm</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={openFormRemoveChatMember}
        onClose={closeFormRemoveChatMemberHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <RemoveChatMember
          closeRemoveChatMember={closeFormRemoveChatMemberHandler}
          chat={props.chat}
          member={props.member}
        />
      </Dialog>
    </Fragment>
  );
};

export default Member;
