import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Chat, Contact, Message } from "vnpt-chat";
import moment from "moment";
import Moment from "react-moment";

interface Props {
  chat: Chat;
  onSendChatUserToListRoomFromChatUser: (chat: Chat | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  member: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const ChatUser: React.FC<Props> = (props) => {
  const vchat = VNPTChat.instance;
  const classes = useStyles();

  const [chat, setChat] = useState<Chat>(props.chat);
  const [user, setUser] = useState(chat.userPartner);
  const [lastMessage, setLastMessage] = useState<Message>();
  const [contact, setContact] = useState<Contact>();

  useEffect(() => {
    if (props.chat) {
      setChat(props.chat);
      setLastMessage(chat.lastMessage);
    }
  }, [chat.lastMessage, props.chat]);

  useEffect(() => {
    if (!user) {
      chat.loadPartner().then(setUser);
    }

    setLastMessage(chat.lastMessage);
  }, [chat.lastMessage, lastMessage, chat, user]);

  useEffect(() => {
    if (user) {
      setContact(vchat.contact(user.id));
    }
  }, [user, vchat]);

  vchat.on("contact.edited", (con) => {
    if (con.userId === contact?.userId) {
      setContact(con);
    }
  });

  const firstLetter =
    contact?.displayName?.slice(0, 1) || user?.displayName.slice(0, 1) || "u";

  const handleOpenBoxChat = () => {
    props.onSendChatUserToListRoomFromChatUser(chat);
  };

  const time = moment(lastMessage?.createdAt);

  return (
    <div className="room" onClick={handleOpenBoxChat}>
      <div className="room_avatar">
        <Avatar
          className={classes.member}
          style={{ backgroundColor: `${user?.avatarColor}` }}
        >
          {firstLetter}
        </Avatar>
      </div>
      <div className="room_infor">
        <div className="room_name">
          <div className="name">
            <p>{contact?.displayName || user?.displayName || "unknown"} </p>
          </div>
          <p className="time">
            <Moment format="HH:mm">{time}</Moment>
          </p>
        </div>

        <div className="lastMessage">
          {lastMessage?.type === "text" && (
            <div className="lastMessage_text">{lastMessage?.text}</div>
          )}
          {lastMessage?.type === "document" && (
            <div className="lastMessage_file">
              <i className="far fa-file"></i>
              Document
            </div>
          )}
          {lastMessage?.type === "file" && (
            <div className="lastMessage_file">
              <i className="far fa-file"></i>
              File
            </div>
          )}
          {lastMessage?.type === "photo" && (
            <div className="lastMessage_file">
              <i className="far fa-file-image"></i>
              Hình ảnh
            </div>
          )}
          {lastMessage?.type === "contact" && (
            <div className="lastMessage_file">
              <i className="far fa-id-badge"></i>
              Danh thiếp
            </div>
          )}
          {lastMessage?.type === "forward" && (
            <p>
              {lastMessage.forwardedMessage.type === "photo" && (
                <div className="lastMessage_file">
                  <i className="far fa-file-image"></i>
                  Hình ảnh
                </div>
              )}
              {lastMessage.forwardedMessage.type === "text" && (
                <div className="lastMessage_text">
                  {lastMessage?.forwardedMessage.text}
                </div>
              )}
              {lastMessage.forwardedMessage.type === "file" && (
                <div className="lastMessage_file">
                  <i className="far fa-file"></i>
                  File
                </div>
              )}
              {lastMessage.forwardedMessage.type === "contact" && (
                <div className="lastMessage_file">
                  <i className="far fa-id-badge"></i>
                  Danh thiếp
                </div>
              )}
            </p>
          )}
          {lastMessage?.type === "system" && (
            <div className="lastMessage_text">
              {lastMessage?.text || "Text of system message"}
            </div>
          )}
          {lastMessage?.type === "deleted" && <div>Tin nhắn đã được xóa</div>}
        </div>
      </div>
    </div>
  );
};

export default ChatUser;
