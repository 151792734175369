import React, { Fragment } from "react";
import { Message } from "vnpt-chat";

interface Props {
  message: Message;
}

const DraftMessageText: React.FC<Props> = ({ message }) => {
  return (
    <Fragment>
      <div className="message_text">{message.text}</div>
      <div className="message_time">Đang gửi</div>
    </Fragment>
  );
};

export default DraftMessageText;
