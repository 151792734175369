import React, { Fragment, useEffect, useState } from "react";
import { Chat } from "vnpt-chat";
import BoxChatDetail from "./BoxChatDetail/BoxChatDetail";
import BoxChatGroup from "./BoxChatGroup/BoxChatGroup";
import BoxChatUser from "./BoxChatUser/BoxChatUser";
import "./style.scss";

interface Props {
  chat?: Chat;
  isActive: boolean;
}

const BoxChat: React.FC<Props> = (props) => {
  const [chat, setChat] = useState<Chat | undefined>();
  const [openBoxChatInfor, setOpenBoxChatInfor] = useState(false);

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  const handleOpenBoxChatInfor = (value: boolean) => {
    setOpenBoxChatInfor(!openBoxChatInfor);
  };
  return (
    <Fragment>
      <div
        className={`${"right"} ${props.isActive ? "activeChat" : ""} ${
          openBoxChatInfor ? "openFormBoxChatInfo" : ""
        }`}
      >
        {chat?.isGroup ? (
          <BoxChatGroup chat={chat} openBoxChatInfor={handleOpenBoxChatInfor} />
        ) : (
          <BoxChatUser chat={chat} openBoxChatInfor={handleOpenBoxChatInfor} />
        )}
      </div>

      <div
        className={`${"boxchat_information"} ${
          openBoxChatInfor ? "openFormBoxChatInfo" : ""
        }`}
      >
        <BoxChatDetail
          chat={chat}
          closeBoxChatDetail={handleOpenBoxChatInfor}
        />
      </div>
    </Fragment>
  );
};

export default BoxChat;
