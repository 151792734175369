import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import VNPTChat, { Chat } from "vnpt-chat";

interface Props {
  chat: Chat;
  openChatGroup: (value1: Chat) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const ChatGroup: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [chat, setChat] = useState<Chat>(props.chat);

  useEffect(() => {
    setChat(props.chat);
  }, [props.chat]);

  const firstLetter = chat?.title?.slice(0, 1) || "u";

  const openChatGroup = () => {
    props.openChatGroup(chat);
  };

  return (
    <div
      className="listChatGroup-group"
      data-id={chat.id}
      onClick={openChatGroup}
    >
      <div className="listChatGroup-group-avatar">
        <Avatar
          className={classes.avatar}
          style={{ backgroundColor: `${chat.avatarColor}` }}
        >
          {firstLetter}
        </Avatar>
      </div>
      <div className="listChatGroup-group-name">{chat.title}</div>
      <div className="listChatGroup-group-member">
        {chat.members.length} thành viên
      </div>
    </div>
  );
};

export default ChatGroup;
