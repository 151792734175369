import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VNPTChat from "vnpt-chat";
import { JwtService } from "../../../../service/jwt.service";
import LoginForm from "../LoginForm/LoginForm";
import "./style.scss";

toast.configure();

async function loginChat(username: string) {
  const vchat = VNPTChat.instance;
  const id = "607d0060cf80802e08b6cb33";
  const code = "N7SXqGLU6E0ywtdcjrZtjSqVy2Pp8rwu";
  const jwtService = new JwtService();
  const token = jwtService.signToken(
    {
      appId: id,
      userId: username,
    },
    code
  );
  await vchat
    .login(id, token)
    .then(() => {
      console.log("done");
    })
    .catch(console.error);
}

const Login: React.FC = () => {
  const History = useHistory();
  const local = localStorage.getItem("signin");
  const signin = local && JSON.parse(local);

  const handleSubmit = (value: any) => {
    if (!signin) {
      localStorage.setItem("signin", JSON.stringify(value));
      loginChat(value.username);
      History.push("/");
      toast.success("LogIn Successful", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
  };

  if (!signin) {
    return (
      <div className="container">
        <LoginForm onSubmit={handleSubmit} />
        <Link className="register" to="/register">
          Don't have an account? Register
        </Link>
      </div>
    );
  } else {
    loginChat(signin.username);
    return <Redirect to="/" />;
  }
};

export default Login;
